const styles = theme => ({
    actionButtons: {
        display: 'flex',
        justifyContent: 'center'
    },
    totalBox: {
        display: 'flex',
        justifyContent: 'center'
    },
    hiddenRow: {
        opacity: '0.4 !important'
    }

});

export default styles;