import React, { useEffect } from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { Constants } from './constants';

export const useBlockNavigation = (shouldBlock) => {
    const { navigator } = React.useContext(UNSAFE_NavigationContext);

    useEffect(() => {
        if (!shouldBlock) return;

        const unblock = navigator.block((tx) => {
            if (window.confirm(Constants.UNDER_REVIEW_CONFIRM_NAVIGATION)) {
                unblock();
                tx.retry();  // Proceed with navigation if confirmed
            }
        });

        return unblock;
    }, [navigator, shouldBlock]);
};