import { types } from "./types";

export const actions = {

    getLeads: payload => ({
        type: types.GET_LEADS,
        payload
    }),
    getLeadsSuccess: payload => ({
        type: types.GET_LEADS_SUCCESS,
        payload
    }),
    getLeadsFailed: payload => ({
        type: types.GET_LEADS_FAILED,
        payload
    }),

    markLeadAsSpam: payload => ({
        type: types.MARK_LEAD_AS_SPAM,
        payload
    }),
    markLeadAsSpamSuccess: payload => ({
        type: types.MARK_LEAD_AS_SPAM_SUCCESS,
        payload
    }),
    markLeadAsSpamFailed: payload => ({
        type: types.MARK_LEAD_AS_SPAM_FAILED,
        payload
    }),

    deleteLead: payload => ({
        type: types.DELETE_LEAD,
        payload
    }),
    deleteLeadSuccess: payload => ({
        type: types.DELETE_LEAD_SUCCESS,
        payload
    }),
    deleteLeadFailed: payload => ({
        type: types.DELETE_LEAD_FAILED,
        payload
    }),

}