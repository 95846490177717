const styles = theme => ({
    subjectContainer: {
        padding: '10px 0',
    },
    emailButton: {
        width: 100
    },
    dialogTitle: {
        margin: 'auto !important',
    },
});

export default styles;