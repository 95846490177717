const styles = theme => ({
    root: {
        display: 'flex',
    },
    content: {
        marginTop: 55,
        flexGrow: 1,
        padding: theme.spacing(3),
    }
});

export default styles;