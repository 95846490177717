import { useEffect, useState, useCallback } from "react";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { packagesActions } from "../../../redux";
import { List } from "@mui/material";
import { getLodgingsByPackageId } from "../../../services/package";
import { toastr } from "react-redux-toastr";
import update from 'immutability-helper';
import LodgingPackageCard from "./LodgingPackageCard";


const LodgingPackageList = (props) => {

    const [lodgingList, setLodgingList] = useState([])

    useEffect(() => {
        if (props.packageId !== 0) {
            fetchLodgingsByPackageId(props.packageId);
        } {
            setLodgingList([]);
        }
    }, [props.packageId]);

    const fetchLodgingsByPackageId = (id) => {
        getLodgingsByPackageId({ id })
            .then(response => {
                if (response.status === 200) {
                    setLodgingList(response.data)
                }
                else {
                    toastr.error('', 'Failed to fetch courses');
                }
            });
    }

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        let updatedList = update(lodgingList, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, lodgingList[dragIndex]]
            ],
        });
        setLodgingList(updatedList);
        let reorderedLodgings = updatedList.map((item, index) => ({ id: item.value, position: index + 1, label: item.label }));
        props.updateLodgingsOrder(reorderedLodgings);
    }, [lodgingList]);

    const updateListItemMostImportantFlag = (id, flagValue) => {
        let flagText = flagValue === 1 ? 'on' : 'off';
        setLodgingList(lodgingList.map(lodging => lodging.value === id ? {...lodging, most_important: flagText} : lodging));
    }

    return (
        <List>
            {lodgingList.map((card, index) => {
                return <LodgingPackageCard
                    key={card.value}
                    index={index}
                    id={card.value}
                    card={card}
                    moveCard={moveCard}
                    updateListItemMostImportantFlag={updateListItemMostImportantFlag}
                />
            })}
        </List>
    );
}

const enhance = compose(
    connect(
        state => ({}),
        dispatch => ({
            updateLodgingsOrder(payload) {
                dispatch(packagesActions.updateLodgingsOrder(payload));
            },
        })
    )
);

export default enhance(LodgingPackageList);