import { Fragment } from "react";
import { Tooltip, Grid, TextField, Button, FormControl, RadioGroup, Radio, FormControlLabel, MenuItem, InputLabel, Select, Checkbox } from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { quotesActions } from '../../../../../redux';
import { useClasses } from '../../../../../config';
import styles from '../styles';


const StatusDetails = (props) => {

    const { occupancy, other_occupancy, status, published } = props;
    const classes = useClasses(styles);

    return (
        <Fragment>
            {/* <RadioGroup
                row
                value={occupancy}
                onChange={(e, value) => updateItem({ target: 'occupancy', value: e.target.value, id: item.id })}
            >
                <span>
                    <span className={classes.radioGroupLabel}>Occupancy</span>
                    <FormControlLabel value='single' control={<Radio />} label='Single' />
                    <FormControlLabel value='double' control={<Radio />} label='Double' />
                    <FormControlLabel value='triple' control={<Radio />} label='Triple' />
                    <FormControlLabel value='quad' control={<Radio />} label='Quad' />
                    <FormControlLabel value='golf_only' control={<Radio />} label='Golf only' />
                    <FormControlLabel value='other' control={<Radio />} label='Other' />
                </span>
                {item.occupancy === 'other' &&
                    <TextField
                        label='Other occupancy'
                        value={other_occupancy}
                        onChange={(e) => updateItem({ target: 'other_occupancy', value: e.target.value, id: item.id })}
                        required
                        size='small'
                    />
                }
            </RadioGroup>
            <RadioGroup
                row
                value={status}
                onChange={(e, value) => updateItem({ taregt: 'status', value: value, id: item.id })}
            >
                <span>
                    <span className={classes.radioGroupLabel}>Status</span>
                    <FormControlLabel value='1' control={<Radio />} label='Pending' />
                    <FormControlLabel value='2' control={<Radio />} label='Confirmed' />
                </span>
            </RadioGroup>
            <RadioGroup
                row
                value={published}
                onChange={(e, value) => updateItem({ target: 'published', value: value, id: item.id })}
            >
                <span>
                    <span className={classes.radioGroupLabel}>Published</span>
                    <FormControlLabel value='0' control={<Radio />} label='Not Published' />
                    <FormControlLabel value='1' control={<Radio />} label='Published' />
                </span>
            </RadioGroup> */}
        </Fragment>
    );
}

const enhance = compose(
    connect(
        state => ({}),
        dispatch => ({
            updateItem(payload) {
                dispatch(quotesActions.updateGolfVacationItem(payload));
            },
        })
    )
);

export default enhance(StatusDetails);