import React, { Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Tooltip, Paper, TableContainer, Table, TableCell, TableHead, TableRow, TableBody } from '@mui/material';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { useClasses } from '../../config';
import styles from './styles';
import moment from 'moment';
import { IconButton } from '@mui/material';


const PreviewGolfVacationItemTooltip = (props) => {

    const classes = useClasses(styles);
    const { id, maxGolfers, selectedRooms, selectedCourses } = props;

    const rooms = selectedRooms[id];
    const courses = selectedCourses[id];

    const roomsColumns = ['Lodging', 'Room', 'Nights', 'Units', 'Cost', 'Markup', 'Price'];
    const coursesColumns = ['Title', 'Date', 'Time', 'TBD', 'GF/SC', 'Cost', 'Markup', 'Price', 'Golfers'];

    return (
        <Tooltip
            onOpen={() => { }}
            placement='top-start'
            PopperProps={{
                sx: {
                    '& div': {
                        padding: 0.5,
                        maxWidth: 2000,
                        justifyContent: 'flex-end'
                    }
                }
            }}
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 22],
                            },
                        },
                    ],
                },
            }}
            title={
                <Fragment>
                    <div style={{ borderRadius: 5 }}>
                        {rooms.length > 0 &&
                            <Fragment>
                                <div className={classes.golfVacationItemSection} style={{ marginTop: 0, marginBottom: 0, height: '2em' }}>Lodging</div>
                                <TableContainer component={Paper}>
                                    <Table padding={'checkbox'} style={{ width: '100%' }} aria-label='simple table'>
                                        <TableHead>
                                            <TableRow>
                                                {roomsColumns.map(column => <TableCell style={{ fontWeight: 900 }} key={column}
                                                    align='center'>{column}</TableCell>)}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rooms.map((row, index) => {
                                                return (
                                                    <TableRow key={index} className={index % 2 ? 'odd-card' : 'even-card'}>
                                                        <TableCell align='center' style={{ minWidth: 230 }}>
                                                            <span>{row.lodging.label}</span>
                                                        </TableCell>
                                                        <TableCell align='center' style={{ minWidth: 230 }}>
                                                            <span>{row.label}</span>
                                                        </TableCell>
                                                        <TableCell align='center' style={{ paddingTop: 0 }}>
                                                            <span>{row.nights}</span>
                                                        </TableCell>
                                                        <TableCell align='center' style={{ paddingTop: 0 }}>
                                                            <span>{row.units}</span>
                                                        </TableCell>
                                                        <TableCell align='center' style={{ paddingTop: 0 }}>
                                                            <span>{parseFloat(row.cost).toFixed(2)}</span>
                                                        </TableCell>
                                                        <TableCell align='center' style={{ paddingTop: 0 }}>
                                                            <span>{parseFloat(row.markup).toFixed(2)}</span>
                                                        </TableCell>
                                                        <TableCell align='center' style={{ paddingTop: 0 }}>
                                                            <span>{parseFloat(row.cost) + parseFloat(row.cost) / 100 * parseFloat(row.markup).toFixed(2)}</span>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Fragment>
                        }
                        {courses.length > 0 &&
                            <Fragment>
                                <div className={classes.golfVacationItemSection} style={{ marginTop: 0, marginBottom: 0, height: '2em' }}>Courses</div>
                                <TableContainer component={Paper}>
                                    <Table padding={'checkbox'} style={{ width: '100%' }} aria-label='simple table'>
                                        <TableHead>
                                            <TableRow>
                                                {coursesColumns.map(column => <TableCell style={{ fontWeight: 900 }} key={column}
                                                    align='center'>{column}</TableCell>)}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {courses.map((row, index) => {
                                                return (
                                                    <TableRow key={index} className={index % 2 ? 'odd-card' : 'even-card'}>
                                                        <TableCell align='center' style={{ minWidth: 230 }}>
                                                            <span>{row.course.label}</span>
                                                        </TableCell>
                                                        <TableCell align='center' style={{ minWidth: 160 }}>
                                                            <span>{row.date_time}</span>
                                                        </TableCell>
                                                        <TableCell align='center' style={{ paddingTop: 0 }}>
                                                            <span>{moment(`${row.hour}:${row.minute}`, 'HH:mm').format('hh:mm a')}</span>
                                                        </TableCell>
                                                        <TableCell align='center' style={{ paddingTop: 0 }}>
                                                            <span>{row.tbd === 'on' ? row.tbd_days : 'Off'}</span>
                                                        </TableCell>
                                                        <TableCell align='center' style={{ paddingTop: 0 }}>
                                                            <span>{row.surcharge ? 'Yes' : 'No'}</span>
                                                        </TableCell>
                                                        <TableCell align='center' style={{ paddingTop: 0 }}>
                                                            <span>{parseFloat(row.price).toFixed(2)}</span>
                                                        </TableCell>
                                                        <TableCell align='center' style={{ paddingTop: 0 }}>
                                                            <span>{parseFloat(row.markup).toFixed(2)}</span>
                                                        </TableCell>
                                                        <TableCell align='center' style={{ paddingTop: 0 }}>
                                                            <span>{(parseFloat(row.price) + parseFloat(row.price) / 100 * parseFloat(row.markup)).toFixed(2)}</span>
                                                        </TableCell>
                                                        <TableCell align='center' style={{ paddingTop: 0 }}>
                                                            <span>{parseInt(row.nr_golfers) === -1 || parseInt(row.nr_golfers) === 0 ? maxGolfers : row.nr_golfers}</span>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Fragment>
                        }
                    </div>
                </Fragment>
            }
        >
            <IconButton sx={{ padding: 0, marginRight: '12px' }}>
                <WysiwygIcon sx={{color: 'black'}}/>
            </IconButton>
        </Tooltip>
    );
}

const enhance = compose(
    connect(
        state => ({
            selectedCourses: state.quotes.selectedCourses,
            selectedRooms: state.quotes.selectedRooms
        }),
        dispatch => ({})
    )
);


export default enhance(PreviewGolfVacationItemTooltip);