import axios from "axios";
import { toastr } from "react-redux-toastr";

//const URL_MAIN = "http://dev.golftripjunkie.com:50000";
const URL_MAIN = "https://prd-internal.golftripjunkie.com";

export const configureAxios = store => {

    axios.defaults.baseURL = URL_MAIN;
    axios.interceptors.request.use(
        opt => {
            const token = window.localStorage.getItem("accessToken");

            if (token) {
                opt.headers.Authorization = "Bearer " + (token || "");
            }
            opt.headers['X-Origin'] = 'backoffice';

            return opt;
        },
        error => Promise.reject(error)
    );

    axios.interceptors.response.use(
        response => {
            // if for some reason we would need to augment the response, we  could do it here
            return response;
        },
        error => {
            if (!error.response) return Promise.reject(new Error("Network error"));

            // TODO: figure out the way to handle errors before reducer
            if (error.response.status === 400) {
                // Bad request
                toastr.error('', 'Something went wrong');
                //return Promise.reject(new Error("Bad Request"));
            }
            if (error.response.status === 401) {
                // Unauthorized
                toastr.error('', 'Unauthorized');
                localStorage.clear();
                window.location.href = '/login';
                return Promise.reject(new Error("Unauthorized"));
            }
            if (error.response.status === 500) {
                toastr.error('', 'Something went wrong');
                return Promise.reject(new Error("Internal server error"));
            }

            return Promise.reject(error);
        }
    );
};