import { call, put, takeLatest, all } from 'redux-saga/effects';
import { packagesService } from '../services';
import {
    packagesActions,
    packagesTypes
} from '../redux';
import { toastr } from 'react-redux-toastr';

function sortItemsByLabel(items) {
    return items.slice().sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));
}

const getResortPackages = function* (action) {
    try {
        const response = yield call(packagesService.getResortPackages, action.payload);
        let packages = response.data.golfPackages;
        let lodgingDropdown = {}
        let roomsDropdown = {}
        packages.map(pack => {
            lodgingDropdown[pack.value] = pack.lodgings;

            pack.lodgings.map(lodging => {
                roomsDropdown[lodging.value] = lodging.children ? lodging.children : [];
            });

        });
        Object.keys(roomsDropdown).forEach(key => {
            if (Array.isArray(roomsDropdown[key])) {
                roomsDropdown[key] = sortItemsByLabel(roomsDropdown[key]);
            }
        });
        yield put(packagesActions.getResortPackagesSuccess({golfPackages: response.data.golfPackages, lodgingDropdownInfo: lodgingDropdown, roomDropdownInfo: roomsDropdown}));
    } catch (e) {
        console.log(e);
        yield put(packagesActions.getResortPackagesFailed());
    }
};

const getCoursesPackages = function* (action) {
    try {
        const response = yield call(packagesService.getCoursesPackages, action.payload);
        console.log(response);
        yield put(packagesActions.getCoursesPackagesSuccess({packages: response.data}));
    } catch (e) {
        console.log(e);
        yield put(packagesActions.getCoursesPackagesFailed());
    }
};

const getLodgingsPackages = function* (action) {
    try {
        const response = yield call(packagesService.getLodgingsPackages, action.payload);
        console.log(response);
        yield put(packagesActions.getLodgingsPackagesSuccess({packages: response.data}));
    } catch (e) {
        console.log(e);
        yield put(packagesActions.getLodgingsPackagesFailed());
    }
};

const getCoursesByPackageId = function* (action) {
    try {
        const response = yield call(packagesService.getCoursesByPackageId, action.payload);
        console.log(response);
        yield put(packagesActions.getCoursesByPackageIdSuccess({courses: response.data}));
    } catch (e) {
        console.log(e);
        yield put(packagesActions.getCoursesByPackageIdFailed());
    }
};

const updateCoursesOrder = function* (action) {
    try {
        yield call(packagesService.updateCoursesOrder, action.payload);
        toastr.success('', 'The order was updated successfully');
    } catch (e) {
        console.log(e);
        toastr.error('', 'Something went wrong');
    }
};

const updateLodgingsOrder = function* (action) {
    try {
        yield call(packagesService.updateLodgingsOrder, action.payload);
        toastr.success('', 'The order was updated successfully');
    } catch (e) {
        console.log(e);
        toastr.error('', 'Something went wrong');
    }
};

const updateCourseMostImportantFlag = function* (action) {
    try {
        yield call(packagesService.updateCourseMostImportantFlag, action.payload);
        toastr.success('', 'The operation has been successfull');
    } catch (e) {
        console.log(e);
        toastr.error('', 'Something went wrong');
    }
};

const updateLodgingMostImportantFlag = function* (action) {
    try {
        yield call(packagesService.updateLodgingMostImportantFlag, action.payload);
        toastr.success('', 'The operation has been successfull');
    } catch (e) {
        console.log(e);
        toastr.error('', 'Something went wrong');
    }
};


export default function* packagesSaga() {
    yield all([
        takeLatest(packagesTypes.GET_RESORT_PACKAGES, getResortPackages),
        takeLatest(packagesTypes.GET_COURSES_PACKAGES, getCoursesPackages),
        takeLatest(packagesTypes.GET_LODGINGS_PACKAGES, getLodgingsPackages),
        takeLatest(packagesTypes.GET_COURSES_BY_PACKAGE_ID, getCoursesByPackageId),
        takeLatest(packagesTypes.UPDATE_COURSES_ORDER, updateCoursesOrder),
        takeLatest(packagesTypes.UPDATE_LODGINGS_ORDER, updateLodgingsOrder),
        takeLatest(packagesTypes.UPDATE_COURSE_MOST_IMPORTANT_FLAG, updateCourseMostImportantFlag),
        takeLatest(packagesTypes.UPDATE_LODGING_MOST_IMPORTANT_FLAG, updateLodgingMostImportantFlag),
    ]);
}