import React from 'react';
import PaymentsHistory from '../PaymentsHistory';
import { Dialog, DialogContent, DialogTitle, Button, DialogActions } from '@mui/material';

const PaymentHistoryDialog = (props) => {

    const { id, open, cancelAction } = props;

    return (
        <Dialog open={open} onClose={cancelAction} fullWidth maxWidth={'lg'}>
            <DialogTitle>Payment History</DialogTitle>
            <DialogContent>
                <PaymentsHistory id={id}/>
                <DialogActions>
                    <Button variant='contained' color='secondary' onClick={cancelAction}>Cancel</Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}

export default PaymentHistoryDialog;