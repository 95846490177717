import { types } from "./types";

const initialState = {
    orderInfo: {
        id: 0,
        product: {},
        billing: {},
        payment: {},
        users: []
    },

    ordersList: [],
    page: 0,
    pages: 0,
    perPage: 0,
    total: 0,

    orderUsers: [],

    orderProductsList: [],
    isProductListLoading: false,

    usersByEmailList: [],
    isUserListLoading: false
};

const orders = (state = initialState, action) => {
    switch (action.type) {
        case types.SAVE_ORDER_SUCCESS:
            return state;
        case types.SAVE_ORDER_FAILED:
            return state;
        case types.GET_ORDER_BY_ID_SUCCESS:
            return { ...state, orderInfo: action.payload }
        case types.GET_ORDER_BY_ID_FAILED:
            return { ...state, orderInfo: { id: 0, product: {}, billing: {}, payment: {}, users: [] } }
        case types.DELETE_ORDER_SUCCESS:
            return state;
        case types.DELETE_ORDER_FAILED:
            return state;
        case types.GET_ORDERS_SUCCESS:
            return {
                ...state, ordersList: action.payload.results,
                page: action.payload.page, pages: action.payload.pages,
                perPage: action.payload.perPage, total: action.payload.total
            }
        case types.GET_ORDERS_FAILED:
            return { ...state, ordersList: [], page: 0, pages: 0, perPage: 0, total: 0 }
        case types.RESET_ORDER_PRODUCTS_LIST:
            return { ...state, orderProductsList: [] }
        case types.GET_ORDER_PRODUCTS_REQUEST:
            return { ...state, isProductListLoading: true }
        case types.GET_ORDER_PRODUCTS_SUCCESS:
            return { ...state, orderProductsList: action.payload.results, isProductListLoading: false }
        case types.GET_ORDER_PRODUCTS_FAILED:
            return { ...state, orderProductsList: [], isProductListLoading: false }
        case types.GET_USERS_BY_EMAIL_REQUEST:
            return { ...state, isUserListLoading: true }
        case types.GET_USERS_BY_EMAIL_SUCCESS:
            return { ...state, usersByEmailList: action.payload.results, isUserListLoading: false }
        case types.GET_USERS_BY_EMAIL_FAILED:
            return { ...state, usersByEmailList: [], isUserListLoading: false }
        case types.GET_USERS_BY_PRODUCT_SUCCESS:
            return { ...state, orderUsers: action.payload }
        case types.GET_USERS_BY_PRODUCT_FAILED:
            return { ...state, orderUsers: [] }
        case types.SPLIT_ORDER_SUCCESS:
            return { ...state, orderInfo: action.payload }
        case types.SPLIT_ORDER_FAILED:
            return { ...state, orderInfo: { id: 0, product: {}, billing: {}, payment: {}, users: [] } }
        default: {
            return state;
        }
    }
};


export default orders;

// forward exports
export { actions } from './actions';
export { types } from './types';