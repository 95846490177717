import { types } from "./types";

export const actions = {
    getResortPackages: payload => ({
        type: types.GET_RESORT_PACKAGES,
        payload
    }), 
    getResortPackagesSuccess: payload => ({
        type: types.GET_RESORT_PACKAGES_SUCCESS,
        payload
    }),
    getResortPackagesFailed: payload => ({
        type: types.GET_RESORT_PACKAGES_FAILED,
        payload
    }),

    getCoursesPackages: payload => ({
        type: types.GET_COURSES_PACKAGES,
        payload
    }), 
    getCoursesPackagesSuccess: payload => ({
        type: types.GET_COURSES_PACKAGES_SUCCESS,
        payload
    }),
    getCoursesPackagesFailed: payload => ({
        type: types.GET_COURSES_PACKAGES_FAILED,
        payload
    }),

    getLodgingsPackages: payload => ({
        type: types.GET_LODGINGS_PACKAGES,
        payload
    }), 
    getLodgingsPackagesSuccess: payload => ({
        type: types.GET_LODGINGS_PACKAGES_SUCCESS,
        payload
    }),
    getLodgingsPackagesFailed: payload => ({
        type: types.GET_LODGINGS_PACKAGES_FAILED,
        payload
    }),
    
    getCoursesByPackageId: payload => ({
        type: types.GET_COURSES_BY_PACKAGE_ID,
        payload
    }), 
    getCoursesByPackageIdSuccess: payload => ({
        type: types.GET_COURSES_BY_PACKAGE_ID_SUCCESS,
        payload
    }),
    getCoursesByPackageIdFailed: payload => ({
        type: types.GET_COURSES_BY_PACKAGE_ID_FAILED,
        payload
    }),

    updateCoursesOrder: payload => ({
        type: types.UPDATE_COURSES_ORDER,
        payload
    }), 
    updateLodgingsOrder: payload => ({
        type: types.UPDATE_LODGINGS_ORDER,
        payload
    }), 

    updateCourseMostImportantFlag: payload => ({
        type: types.UPDATE_COURSE_MOST_IMPORTANT_FLAG,
        payload
    }), 
    updateLodgingMostImportantFlag: payload => ({
        type: types.UPDATE_LODGING_MOST_IMPORTANT_FLAG,
        payload
    }), 
}