import userSaga from './user';
import quotesSaga from './quotes';
import leadsSaga from './leads';
import ordersSaga from './orders';
import packagesSaga from './packages';
import { all } from 'redux-saga/effects';

export default function* rootSaga() {
	yield all([
		userSaga(),
		quotesSaga(),
		leadsSaga(),
		ordersSaga(),
		packagesSaga()
	]);
}