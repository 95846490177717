import { calculateTotals, sortCoursesByDate } from "../../config";
import { types } from "./types";

const initialState = {
    quoteInfo: {
        id: '',
        title: '',
        email: '',
        seoLink: '',
        seoTitle: '',
        quoteUnderReview: 0,
        suggestionContent: '',
    },
    emailInfo: {},
    password: '',
    golfVacationItems: [],
    selectedRooms: {},
    selectedCourses: {},

    cloneInProgress: false,

    mainQuotes: {
        quotesList: [],
        page: 0,
        pages: 0,
        perPage: 0,
        total: 0,
    },

    inProgressQuotePayments: {
        quotesList: [],
        page: 0,
        pages: 0,
        perPage: 0,
        total: 0,
    },

    dueQuotesList: [],

    paidQuotesList: [],

    teeTimeReminders: {
        primary: [],
        secondary: [],
        pendingPrimary: [],
        pendingSecondary: []
    },
};

const quotes = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_QUOTE_BY_ID_SUCCESS:
            return {
                ...state, quoteInfo: action.payload.quoteInfo, emailInfo: action.payload.emailInfo,
                password: action.payload.password, golfVacationItems: action.payload.golfVacationItems,
                selectedCourses: action.payload.selectedCourses, selectedRooms: action.payload.selectedRooms
            };
        case types.GET_QUOTE_BY_ID_FAILED:
            return { ...state };
        case types.SET_QUOTE_INFO:
            return { ...state, quoteInfo: action.payload.info };
        case types.UPDATE_QUOTE_INFO:
            return { ...state, quoteInfo: { ...state.quoteInfo, [action.payload.target]: action.payload.value } };
        case types.UPDATE_QUOTE_SUCCESS:
            return { ...state, emailInfo: action.payload.emailInfo };
        case types.DELETE_QUOTE_SUCCESS:
            return { ...state }
        case types.DELETE_QUOTE_FAILED:
            return { ...state }
        case types.HIDE_QUOTE_SUCCESS:
            return { ...state }
        case types.HIDE_QUOTE_FAILED:
            return { ...state }
        case types.GET_QUOTES_SUCCESS:
            return {
                ...state, mainQuotes: {
                    quotesList: action.payload.results,
                    page: action.payload.page, pages: action.payload.pages,
                    perPage: action.payload.perPage, total: action.payload.total
                }
            };
        case types.GET_QUOTES_FAILED:
            return { ...state, mainQuotes: { quotesList: [], page: 0, pages: 0, perPage: 0, total: 0 } };
        case types.GET_IN_PROGRESS_QUOTE_PAYMENTS_SUCCESS:
            return {
                ...state, inProgressQuotePayments: {
                    quotesList: action.payload.results,
                    page: Number(action.payload.page), pages: action.payload.pages,
                    perPage: Number(action.payload.perPage), total: action.payload.total
                }
            };
        case types.GET_IN_PROGRESS_QUOTE_PAYMENTS_FAILED:
            return { ...state, inProgressQuotePayments: { quotesList: [], page: 0, pages: 0, perPage: 0, total: 0 } };
        case types.HIDE_PAYMENT_IN_PROGRESS_SUCCESS:
            return {
                ...state, inProgressQuotePayments: {
                    ...state.inProgressQuotePayments, quotesList: state.inProgressQuotePayments.quotesList.map(
                        item => item.golf_vacation_item_id === action.payload.id ? { ...item, hidden: action.payload.hidden } : item
                    )
                }
            };
        case types.SET_QUOTE_CONFIRM_FLAG_SUCCESS:
            return { ...state }
        case types.SET_QUOTE_CONFIRM_FLAG_FAILED:
            return { ...state }
        case types.SET_QUOTE_PUBLISH_FLAG_SUCCESS:
            return { ...state }
        case types.SET_QUOTE_PUBLISH_FLAG_FAILED:
            return { ...state }
        case types.SET_QUOTE_UNDER_REVIEW_FLAG_SUCCESS:
            return { ...state, quoteInfo: { ...state.quoteInfo, quoteUnderReview: action.payload.flag } }
        case types.GET_DUE_QUOTES_SUCCESS:
            return { ...state, dueQuotesList: action.payload };
        case types.GET_DUE_QUOTES_FAILED:
            return { ...state, dueQuotesList: [] };
        case types.GET_TEE_TIME_REMINDERS_SUCCESS:
            return { ...state, teeTimeReminders: action.payload }
        case types.GET_TEE_TIME_REMINDERS_FAILED:
            return { ...state }
        case types.GET_PAID_QUOTES_SUCCESS:
            return { ...state, paidQuotesList: action.payload };
        case types.GET_PAID_QUOTES_FAILED:
            return { ...state, paidQuotesList: [] };
        case types.SEND_VOUCHER_SUCCESS:
            return {
                ...state, paidQuotesList: state.paidQuotesList.map(
                    item => item.quote_id === action.payload.id ? { ...item, voucher_email_sent: 'Yes' } : item
                )
            };
        case types.SEND_AFTER_TRIP_FOLLOWUP_EMAIL_SUCCESS:
            return {
                ...state, paidQuotesList: state.paidQuotesList.map(
                    item => item.quote_id === action.payload.id ? { ...item, after_follow_up: 1 } : item
                )
            };
        case types.SEND_THANK_YOU_EMAIL_SUCCESS:
            return {
                ...state, paidQuotesList: state.paidQuotesList.map(
                    item => item.quote_id === action.payload.id ? { ...item, thank_you_email: 1 } : item
                )
            };
        case types.ADD_GOLF_VACATION_ITEM_SUCCESS:
            let name;
            if (state.golfVacationItems.length === 0) {
                name = action.payload.item.first_name ? action.payload.item.first_name : '';
            }
            return {
                ...state, golfVacationItems: [...state.golfVacationItems, action.payload.item],
                selectedRooms: { ...state.selectedRooms, [action.payload.item.id]: [] },
                selectedCourses: { ...state.selectedCourses, [action.payload.item.id]: [] },
                emailInfo: name ? { ...state.emailInfo, name: name } : state.emailInfo
            };
        case types.CLONE_GOLF_VACATION_ITEM_REQUEST:
            return { ...state, cloneInProgress: true };
        case types.CLONE_GOLF_VACATION_ITEM_SUCCESS:
            return {
                ...state, golfVacationItems: [
                    ...state.golfVacationItems.slice(0, action.payload.index),
                    action.payload.item,
                    ...state.golfVacationItems.slice(action.payload.index),
                ],
                selectedRooms: { ...state.selectedRooms, [action.payload.item.id]: action.payload.selectedRooms },
                selectedCourses: { ...state.selectedCourses, [action.payload.item.id]: action.payload.selectedCourses },
                cloneInProgress: false
            };
        case types.CLONE_GOLF_VACATION_ITEM_FAILED:
            return { ...state, cloneInProgress: false };
        case types.DELETE_GOLF_VACATION_ITEM_SUCCESS:
            return {
                ...state, golfVacationItems: state.golfVacationItems.filter(item => item.id !== action.payload.id)
            };
        case types.SET_GOLF_VACATION_ITEMS:
            return { ...state, golfVacationItems: action.payload.items }
        case types.UPDATE_GOLF_VACATION_ITEM:
            return {
                ...state, golfVacationItems: state.golfVacationItems.map(
                    item => item.id === action.payload.id ? { ...item, [action.payload.target]: action.payload.value } : item
                )
            }
        case types.UPDATE_GOLF_VACATION_ITEM_OCCUPANCY:
            let updatedTotals = updateTotals(action, state);
            return {
                ...state, golfVacationItems: state.golfVacationItems.map(
                    item => item.id === action.payload.id ? { ...item, occupancy: action.payload.occupancy, totals: updatedTotals } : item
                )
            }
        case types.UPDATE_GOLF_VACATION_ITEM_ADDITIONAL_AMOUNTS:
            let latestTotals = updateTotals(action, state);
            return {
                ...state, golfVacationItems: state.golfVacationItems.map(
                    item => item.id === action.payload.id ? { ...item, additional_amounts: action.payload.additionalAmounts, totals: latestTotals } : item
                )
            }
        case types.REPLACE_GOLF_VACATION_ITEM:
            return {
                ...state, golfVacationItems: state.golfVacationItems.map(
                    item => item.id === action.payload.id ? action.payload.item : item
                )
            }
        case types.REFRESH_GOLF_VACATION_ITEM_NAME:
            let titleData = buildTitleData(state.quoteInfo);
            return {
                ...state, golfVacationItems: state.golfVacationItems.map(
                    item => item.id === action.payload.id ?
                        {
                            ...item,
                            first_name: titleData.first_name,
                            last_name: titleData.last_name ? titleData.last_name : item.last_name,
                            reservation_name: titleData.reservation_name
                        } : item
                )
            }
        case types.SET_SELECTED_ROOMS:
            return { ...state, selectedRooms: action.payload.selectedRooms }
        case types.UPDATE_SELECTED_ROOM:
            const { itemId, id, target, value } = action.payload;
            return {
                ...state, selectedRooms: {
                    ...state.selectedRooms,
                    [itemId]: state.selectedRooms[itemId].map(room => room.internal_id == id ? { ...room, [target]: value } : room)
                }
            }
        case types.REPLACE_SELECTED_ROOM:
            return {
                ...state, selectedRooms: {
                    ...state.selectedRooms,
                    [action.payload.itemId]: state.selectedRooms[action.payload.itemId].map(room =>
                        room.internal_id === action.payload.id ? { ...action.payload.room } : room
                    )
                }
            }
        case types.ADD_SELECTED_ROOM_SUCCESS:
            return {
                ...state, selectedRooms: {
                    ...state.selectedRooms,
                    [action.payload.itemId]: [...state.selectedRooms[action.payload.itemId], { ...action.payload.newEntry }]
                }
            }
        case types.DELETE_SELECTED_ROOM_SUCCESS:
            return {
                ...state, selectedRooms: {
                    ...state.selectedRooms,
                    [action.payload.itemId]: state.selectedRooms[action.payload.itemId].filter(item => item.internal_id !== action.payload.id)
                }
            }
        case types.SET_SELECTED_COURSES:
            return { ...state, selectedCourses: action.payload.selectedCourses }
        case types.UPDATE_SELECTED_COURSE:
            if (action.payload.target === 'date_time') {
                return {
                    ...state, selectedCourses: {
                        ...state.selectedCourses,
                        [action.payload.itemId]: sortCoursesByDate(state.selectedCourses[action.payload.itemId].map(room =>
                            room.internal_id === action.payload.id ? { ...room, [action.payload.target]: action.payload.value } : room
                        ))
                    }
                }
            }
            else {
                return {
                    ...state, selectedCourses: {
                        ...state.selectedCourses,
                        [action.payload.itemId]: state.selectedCourses[action.payload.itemId].map(room =>
                            room.internal_id === action.payload.id ? { ...room, [action.payload.target]: action.payload.value } : room
                        )
                    }
                }
            }
        case types.REPLACE_SELECTED_COURSE:
            if (action.payload.isDateTimeChanged) {
                return {
                    ...state, selectedCourses: {
                        ...state.selectedCourses,
                        [action.payload.itemId]: sortCoursesByDate(state.selectedCourses[action.payload.itemId].map(course =>
                            course.internal_id === action.payload.id ? { ...action.payload.course } : course
                        ))
                    }
                }
            } else {
                return {
                    ...state, selectedCourses: {
                        ...state.selectedCourses,
                        [action.payload.itemId]: state.selectedCourses[action.payload.itemId].map(course =>
                            course.internal_id === action.payload.id ? { ...action.payload.course } : course
                        )
                    }
                }
            }
        case types.UPDATE_SELECTED_COURSE_TBD:
            return {
                ...state, selectedCourses: {
                    ...state.selectedCourses,
                    [action.payload.itemId]: state.selectedCourses[action.payload.itemId].map(course =>
                        course.internal_id == action.payload.id ? { ...course, tbd: action.payload.tbd, tbd_days: action.payload.tbd_days } : course
                    )
                }
            }
        case types.UPDATE_TWO_ROUNDS_COURSE:
            return {
                ...state, selectedCourses: {
                    ...state.selectedCourses,
                    [action.payload.itemId]: state.selectedCourses[action.payload.itemId].map(course =>
                        course.internal_id == action.payload.id ? { ...course, price: 0, markup: 0 } : course
                    )
                }
            }
        case types.DELETE_SELECTED_COURSE:
            return {
                ...state, selectedCourses: {
                    ...state.selectedCourses,
                    [action.payload.itemId]: state.selectedCourses[action.payload.itemId].filter(item => item.internal_id !== action.payload.id)
                }
            }
        case types.ADD_SELECTED_COURSE:
            return {
                ...state, selectedCourses: {
                    ...state.selectedCourses,
                    [action.payload.itemId]: sortCoursesByDate([...state.selectedCourses[action.payload.itemId], { ...action.payload.newEntry, tbd: action.payload.newEntry.tbd === false ? 'off' : 'on' }])
                }
            }
        case types.SEND_PAYMENT_REMINDER_EMAIL_SUCCESS:
            return {
                ...state, dueQuotesList: state.dueQuotesList.map(
                    item => item.id == action.payload.id ? { ...item, payment_email: 1 } : item
                )
            }
        case types.SEND_FOLLOWUP_EMAIL_SUCCESS:
            return {
                ...state, mainQuotes: {
                    ...state.mainQuotes, quotesList: state.mainQuotes.quotesList.map(
                        item => item.id == action.payload.id ? { ...item, follow_up_1: 1 } : item
                    )
                }
            }
        case types.SET_FOLLOWUP_FLAG_SUCCESS:
            return {
                ...state, mainQuotes: {
                    ...state.mainQuotes, quotesList: state.mainQuotes.quotesList.map(
                        item => item.id == action.payload.id ? { ...item, follow_up_1: action.payload.body.value } : item
                    )
                }
            }
        default: {
            return state;
        }
    }
};

const buildTitleData = (quoteInfo) => {
    let titleData = {};
    let titleParts = quoteInfo.title.split(' ');
    if (titleParts.length > 1) {
        titleData.first_name = titleParts[0];
        titleData.last_name = titleParts[1];
        titleData.reservation_name = titleParts[0] + ' ' + titleParts[1];
    } else {
        titleData.first_name = quoteInfo.title;
        titleData.reservation_name = quoteInfo.title;
    }
    return titleData;
}

const updateTotals = (action, state) => {
    let lodgingList;
    if (action.payload.occupancy == 'golf_only') {
        lodgingList = [];
    } else {
        lodgingList = state.selectedRooms[action.payload.id]
    }

    return calculateTotals({
        selectedCourses: state.selectedCourses[action.payload.id],
        selectedRooms: lodgingList,
        additionalAmounts: action.payload.additionalAmounts
    });
}

export default quotes;

// forward exports
export { actions } from './actions';
export { types } from './types';