import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {reducer as toastrReducer} from 'react-redux-toastr'

import { apiConfig } from '../config'
import sagas from '../sagas';

import user from './user';
import quotes from "./quotes";
import leads from "./leads";
import orders from "./orders";
import packages from './packages';

export const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

const rootReducer = {
    user: user, 
    quotes: quotes,
    leads: leads,
    orders: orders,
    packages: packages,
    router: routerReducer,
    toastr: toastrReducer
}

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: { warnAfter: 64 },
    serializableCheck: { warnAfter: 64 },
  }).concat(sagaMiddleware, routerMiddleware)
});

sagaMiddleware.run(sagas);
apiConfig.configureAxios(store);


export const history = createReduxHistory(store);
export default store;

export {
	actions as userActions,
    types as userTypes,
} from './user';

export {
    actions as quotesActions,
    types as quotesTypes
} from './quotes';

export {
    actions as leadsActions,
    types as leadsTypes
} from './leads';

export {
    actions as ordersActions,
    types as ordersTypes
} from './orders';

export {
    actions as packagesActions,
    types as packagesTypes
} from './packages';
