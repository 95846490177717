import { compose } from 'redux';
import { connect } from 'react-redux';
import { userActions } from '../../redux';
import Login from './Login';

const enhance = compose(
    connect(
        state => ({
            loginFailed: state.user.loginFailed,
        }),
        dispatch => ({
            login(payload) {
                dispatch(userActions.login(payload));
            },
            setLoginFailed(payload) {
                dispatch(userActions.setLoginFailed(payload));
            }
        })
    )
);

export default enhance(Login);