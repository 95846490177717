export const types = {

    GET_LEADS: 'GET_LEADS',
    GET_LEADS_SUCCESS: 'GET_LEADS_SUCCESS',
    GET_LEADS_FAILED: 'GET_LEADS_FAILED',

    MARK_LEAD_AS_SPAM: 'MARK_LEAD_AS_SPAM',
    MARK_LEAD_AS_SPAM_SUCCESS: 'MARK_LEAD_AS_SPAM_SUCCESS',
    MARK_LEAD_AS_SPAM_FAILED: 'MARK_LEAD_AS_SPAM_FAILED',

    DELETE_LEAD: 'DELETE_LEAD',
    DELETE_LEAD_SUCCESS: 'DELETE_LEAD_SUCCESS',
    DELETE_LEAD_FAILED: 'DELETE_LEAD_FAILED',
}