import { compose } from 'redux';
import { connect } from 'react-redux';
import { quotesActions } from '../../redux';
import PaidQuotes from './PaidQuotes';

const enhance = compose(
    connect(
        state => ({
            paidQuotesList: state.quotes.paidQuotesList,
        }),
        dispatch => ({
            getPaidQuotes(payload) {
                dispatch(quotesActions.getPaidQuotes(payload));
            }, 
            sendPreTripFollowupEmail(payload) {
                dispatch(quotesActions.sendPreTripFollowupEmail(payload));
            },
            sendVoucher(payload) {
                dispatch(quotesActions.sendVoucher(payload));
            },
            sendAfterTripFollowupEmail(payload) {
                dispatch(quotesActions.sendAfterTripFollowupEmail(payload));
            },
            sendThankYouEmail(payload) {
                dispatch(quotesActions.sendThankYouEmail(payload));
            }
        })
    )
);

export default enhance(PaidQuotes);