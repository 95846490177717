import { compose } from 'redux';
import { connect } from 'react-redux';
import { quotesActions } from '../../redux';
import InProgressQuotePayments from './InProgressQuotePayments';

const enhance = compose(
    connect(
        state => ({
            quotesList: state.quotes.inProgressQuotePayments.quotesList,
            totalRows: state.quotes.inProgressQuotePayments.total
        }),
        dispatch => ({
            getInProgressQuotePayments(payload) {
                dispatch(quotesActions.getInProgressQuotePayments(payload));
            },
            hidePaymentInProgress(payload) {
                dispatch(quotesActions.hidePaymentInProgress(payload));
            }
        })
    )
);

export default enhance(InProgressQuotePayments);