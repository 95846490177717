import React from 'react';
import { Autocomplete, TextField, Paper, Popper } from '@mui/material';


const GeneralAutocomplete = (props) => {

    const { label, options, isLoading, value, handleChange, optionKey } = props;
    const [inputValue, setInputValue] = React.useState('');

    return (
        <Autocomplete
            size='small'
            PopperComponent={CustomPopper}
            sx={{ width: '100%', marginBottom: '10px' }}
            options={options}
            loading={isLoading}
            value={value}
            onChange={(event, value) => { handleChange(value) }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            disableClearable
            getOptionLabel={(option) => option[optionKey] ? option[optionKey] : ''}
            renderOption={(props, option) => (
                <li {...props} key={option[optionKey]}>
                    {option[optionKey]}
                </li>
            )}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderInput={(params) => (
                <TextField
                    label={label}
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                    }}
                />
            )}
        />
    );
}

const CustomPopper = (props) => { 
    return <Popper 
                {...props} 
                placement="bottom" 
                sx={{ height: "10px", }} 
                style={{ width: props.anchorEl.clientWidth }} 
            />
}

export default GeneralAutocomplete;