import { compose } from 'redux';
import { connect } from 'react-redux';
import { leadsActions } from '../../redux';
import Leads from './Leads';

const enhance = compose(
    connect(
        state => ({
            leadsList: state.leads.leadsList,
            totalPages: state.leads.total
        }),
        dispatch => ({
            getLeads(payload) {
                dispatch(leadsActions.getLeads(payload));
            },
            markLeadAsSpam(payload) {
                dispatch(leadsActions.markLeadAsSpam(payload));
            },
            deleteLead(payload) {
                dispatch(leadsActions.deleteLead(payload));
            }
        })
    )
);

export default enhance(Leads);