import { useDrag } from 'react-dnd'
import { ItemTypes } from '../../../config/ItemTypes'
import React, { Fragment, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const style = {
    width: 310,
    height: 350,
    position: 'absolute',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '5px',
    backgroundColor: 'whitesmoke',
    padding: '0.5rem 0.5rem 0.5rem 1rem',
    cursor: 'move',
    zIndex: 100,
    overflowY: 'scroll',
    overflowX: 'hidden',
    whiteSpace: 'pre-wrap',
    textAlign: 'left'
}
export const LeadInfoBox = ({ left, top, hideSourceOnDrag, info, onCloseAction }) => {

    const parsedText = useMemo(() => {
        const lines = info.split('\n').map((line, index) => {
            if (line.startsWith('<b>') && line.endsWith('</b>')) {
                return <b key={index}>{line.slice(3, -4)}</b>;
            }
            if (line.endsWith('<br/>')) {
                return line.slice(0, -5);
            }
            return line;
        });

        return lines.map((line, index) => (
            <Fragment key={index}>
                {line}
                <br />
            </Fragment>
        ));
    }, [info]);

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.BOX,
            item: { left, top },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [left, top],
    )

    if (isDragging && hideSourceOnDrag) {
        return <div ref={drag} />
    }

    return (
        <div
            className='box'
            ref={drag}
            style={{ ...style, left, top }}
            data-testid='box'
        >
            <div style={{ display: 'flex' }}>
                <div style={{ maxWidth: 245 }}>{parsedText}</div>
                <div>
                    <IconButton onClick={onCloseAction} sx={{ padding: '0 0 0 2px', color: 'black' }}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

export default LeadInfoBox;