const styles = theme => ({
    actionButtons: {
        minWidth: 120,
        display: 'flex',
        justifyContent: 'center'
    },
    ordersTable: {
        '& table': {
            '& td': {
                paddingRight: '0.2rem',
                paddingLeft: '0.2rem',
            },
            '& th': {
                paddingLeft: '0.2rem',
                paddingRight: '0.2rem',
                lineHeight: '1.2rem',
                fontWeight: 600,
                '& div': {
                    whiteSpace: 'unset'
                }
            }
        }
    },
    tableTitle: {
        padding: '10px 10px 20px 20px',
        fontWeight: '500'
    },
    searchContainer: {
        width: '90%',
        display: 'flex'
    },
    searchBox: {
        width: '40%',
    },
    resetButton: {
        marginLeft: '10px !important',
    },
});

export default styles;