import styles from "../styles";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { packagesActions } from "../../../redux";
import { useClasses } from "../../../config";
import { useEffect, useState, Fragment } from "react";
import { List, ListItemText, ListItem, ListItemButton, Grid, TextField } from "@mui/material";
import LodgingPackageList from "./LodgingPackageList";


const LodgingManagement = (props) => {

    const [searchText, setSearchText] = useState('');
    const [activePackage, setActivePackage] = useState(0);
    const classes = useClasses(styles);

    useEffect(() => {
        props.getLodgingsPackages();
    }, []);

    const getFilteredPackages = () => {
        if (searchText !== '') {
            return props.lodgingsPackages.filter(pkg => pkg.title.toLowerCase().includes(searchText.toLowerCase()))
        }
        return props.lodgingsPackages;
    }

    const handleSearchEvent = (value) => {
        setSearchText(value);
        setActivePackage(0);
    }

    return (
        <Fragment>
            <div className={classes.sectionTitle}>Reorder Lodgings</div>
            <Grid container className={classes.content} columnSpacing={3}>
                <Grid item xs={6} md={4}>
                    <TextField
                        label='Search'
                        variant='outlined'
                        value={searchText}
                        onChange={(e) => handleSearchEvent(e.target.value)}
                        fullWidth
                    />
                    <List>
                        {getFilteredPackages().map(card => {
                            return <ListItem key={card.id} className={card.id === activePackage ? classes.selectedPackage : classes.package}>
                                <ListItemButton onClick={() => setActivePackage(card.id)}>
                                    <ListItemText primary={card.title} />
                                </ListItemButton>
                            </ListItem>
                        })}
                    </List>
                </Grid>
                <Grid item xs={6} md={4}>
                    <LodgingPackageList packageId={activePackage} />
                </Grid>
            </Grid>
        </Fragment>
    );
}

const enhance = compose(
    connect(
        state => ({
            lodgingsPackages: state.packages.lodgingsPackages,
        }),
        dispatch => ({
            getLodgingsPackages(payload) {
                dispatch(packagesActions.getLodgingsPackages(payload));
            },
        })
    )
);

export default enhance(LodgingManagement);