import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';

const ConfirmDialog = (props) => {

    const { open, text, okText, okAction, cancelText, cancelAction } = props;
    return (
        <Dialog open={open}>
            <DialogTitle>Warning</DialogTitle>
            <Divider />
            <DialogContent>
                <div>{text}</div>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={cancelAction}>{cancelText}</Button>
                <Button variant='contained' onClick={okAction} color='secondary'>{okText}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;