import React, {useMemo} from 'react';

const validCurrencies = new Set(['USD', 'EUR', 'GBP', 'CAD']);

const PriceDisplay = ({ amount, currency = 'USD' }) => {
    const currencyCode = validCurrencies.has(currency) ? currency : 'USD';

    const formattedAmount = useMemo(() => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0        
        }).format(amount);
    }, [amount, currencyCode]);

    return <span>{formattedAmount}</span>;
}

export default PriceDisplay;