import { Fragment, useState, useRef } from "react";
import { useClasses } from "../../config";
import styles from "./styles";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button } from "@mui/material";
import { uploadLodgingPrices, uploadCoursesPrices } from "../../services/pricing";
import { toastr } from "react-redux-toastr";


const PricingSystem = () => {

    const coursesFileInputRef = useRef(null);
    const lodgingFileInputRef = useRef(null);
    const [selectedCoursesFile, setSelectedCoursesFile] = useState('');
    const [selectedLodgingFile, setSelectedLodgingFile] = useState('');
    const classes = useClasses(styles);

    const handleCoursesFileUpload = () => {
        const formData = new FormData();
        formData.append(
            'pricingFile',
            selectedCoursesFile,
            selectedCoursesFile.name
        );
        uploadCoursesFile(formData);
    }

    const handleLodgingFileUpload = () => {
        const formData = new FormData();
        formData.append(
            'pricingFile',
            selectedLodgingFile,
            selectedLodgingFile.name
        );
        uploadLodgingFile(formData);
    }

    const uploadCoursesFile = (payload) => {
        uploadCoursesPrices(payload)
            .then(response => {
                if (response.status === 200) {
                    toastr.success('', 'The prices were successfully uploaded');
                }
                else {
                    toastr.error('', 'Something went wrong');
                }
            });
        setSelectedCoursesFile('');
        if (coursesFileInputRef.current) {
            coursesFileInputRef.current.value = '';
        }
    }

    const uploadLodgingFile = (payload) => {
        uploadLodgingPrices(payload)
            .then(response => {
                if (response.status === 200) {
                    toastr.success('', 'The prices were successfully uploaded');
                }
                else {
                    toastr.error('', 'Something went wrong');
                }
            });
        setSelectedLodgingFile('');
        if (lodgingFileInputRef.current) {
            lodgingFileInputRef.current.value = '';
        }
    }

    return (
        <Fragment>
            <div className={classes.coursesSection}>
                <div className={classes.sectionTitle}>Upload Courses Prices</div>
                <div className={classes.buttonsContainer}>
                    <div className={classes.selectFileContainer}>
                        <Button
                            component='label'
                            variant='outlined'
                            startIcon={<UploadFileIcon />}
                            sx={{ marginRight: '1rem' }}
                            size='large'
                        >
                            Select File
                            <input
                                ref={coursesFileInputRef}
                                type='file'
                                accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                hidden
                                onChange={(e) => setSelectedCoursesFile(e.target.files[0])}
                            />
                        </Button>
                        <div className={classes.selectedFileName}>
                            {
                                selectedCoursesFile ? selectedCoursesFile.name : 'No file selected'
                            }
                        </div>
                    </div>
                    <Button
                        variant='contained'
                        onClick={handleCoursesFileUpload}
                    >Upload file</Button>
                </div>
            </div>
            <div className={classes.lodgingSection}>
                <div className={classes.sectionTitle}>Upload Lodging Prices</div>
                <div className={classes.buttonsContainer}>
                    <div className={classes.selectFileContainer}>
                        <Button
                            component='label'
                            variant='outlined'
                            startIcon={<UploadFileIcon />}
                            sx={{ marginRight: '1rem' }}
                            size='large'
                        >
                            Select File
                            <input
                                ref={lodgingFileInputRef}
                                type='file'
                                accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                hidden
                                onChange={(e) => setSelectedLodgingFile(e.target.files[0])}
                            />
                        </Button>
                        <div className={classes.selectedFileName}>
                            {
                                selectedLodgingFile ? selectedLodgingFile.name : 'No file selected'
                            }
                        </div>
                    </div>
                    <Button
                        variant='contained'
                        onClick={handleLodgingFileUpload}
                    >Upload file</Button>
                </div>
            </div>
        </Fragment>
    );
}

export default PricingSystem;