export const types = {

    SAVE_ORDER: 'SAVE_ORDER',
    SAVE_ORDER_SUCCESS: 'SAVE_ORDER_SUCCESS',
    SAVE_ORDER_FAILED: 'SAVE_ORDER_FAILED',

    GET_ORDER_BY_ID: 'GET_ORDER_BY_ID',
    GET_ORDER_BY_ID_SUCCESS: 'GET_ORDER_BY_ID_SUCCESS',
    GET_ORDER_BY_ID_FAILED: 'GET_ORDER_BY_ID_FAILED',

    DELETE_ORDER: 'DELETE_ORDER',
    DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
    DELETE_ORDER_FAILED: 'DELETE_ORDER_FAILED',

    GET_ORDERS: 'GET_ORDERS',
    GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
    GET_ORDERS_FAILED: 'GET_ORDERS_FAILED',

    RESET_ORDER_PRODUCTS_LIST: 'RESET_ORDER_PRODUCTS_LIST',
    GET_ORDER_PRODUCTS_REQUEST: 'GET_ORDER_PRODUCTS_REQUEST',
    GET_ORDER_PRODUCTS: 'GET_ORDER_PRODUCTS',
    GET_ORDER_PRODUCTS_SUCCESS: 'GET_ORDER_PRODUCTS_SUCCESS',
    GET_ORDER_PRODUCTS_FAILED: 'GET_ORDER_PRODUCTS_FAILED',

    GET_USERS_BY_EMAIL_REQUEST: 'GET_USERS_BY_EMAIL_REQUEST',
    GET_USERS_BY_EMAIL: 'GET_USERS_BY_EMAIL',
    GET_USERS_BY_EMAIL_SUCCESS: 'GET_USERS_BY_EMAIL_SUCCESS',
    GET_USERS_BY_EMAIL_FAILED: 'GET_USERS_BY_EMAIL_FAILED',

    GET_USERS_BY_PRODUCT: 'GET_USERS_BY_PRODUCT',
    GET_USERS_BY_PRODUCT_SUCCESS: 'GET_USERS_BY_PRODUCT_SUCCESS',
    GET_USERS_BY_PRODUCT_FAILED: 'GET_USERS_BY_PRODUCT_FAILED',

    SPLIT_ORDER: 'SPLIT_ORDER',
    SPLIT_ORDER_SUCCESS: 'SPLIT_ORDER_SUCCESS',
    SPLIT_ORDER_FAILED: 'SPLIT_ORDER_FAILED',

    MOVE_ORDER: 'MOVE_ORDER',
    MOVE_ORDER_SUCCESS: 'MOVE_ORDER_SUCCESS',
    MOVE_ORDER_FAILED: 'MOVE_ORDER_FAILED',
}