
import { Grid, TextField,} from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useClasses } from '../../../../../config';
import styles from '../styles';
import RichTextEditor from '../../../../../components/editor';
import { quotesActions } from '../../../../../redux';


const Descriptions = (props) => {

    const {id, description, confirmation_id, resort_title, check_in_location, reservation_name, updateItem} = props;
    const classes = useClasses(styles);

    return (
        <Grid container className={classes.descriptionsContainer}>
            <Grid item xs={6}>
                <RichTextEditor
                    // initialContent={quoteInfo.initialContent}
                    content={description}
                    handleContentChange={(value) => updateItem({ target: 'description', value: value, id: id })}
                    height={400}
                />
            </Grid>
            <Grid item xs={6} className={classes.descriptionFieldsContainer}>
                <TextField
                    label='Confirmation #'
                    value={confirmation_id}
                    onChange={(e) => updateItem({ target: 'confirmation_id', value: e.target.value, id: id })}
                    className={classes.descriptionField}
                />
                <TextField
                    label='Resort Title'
                    value={resort_title}
                    onChange={(e) => updateItem({ target: 'resort_title', value: e.target.value, id: id })}
                    className={classes.descriptionField}
                />
                <TextField
                    label='Check in location'
                    value={check_in_location}
                    onChange={(e) => updateItem({ target: 'check_in_location', value: e.target.value, id: id })}
                    className={classes.descriptionField}
                />
                <TextField
                    label='Reservation name'
                    value={reservation_name}
                    onChange={(e) => updateItem({ target: 'reservation_name', value: e.target.value, id: id })}
                    className={classes.descriptionField}
                />
            </Grid>
        </Grid>
    );
}

const enhance = compose(
    connect(
        state => ({}),
        dispatch => ({
            updateItem(payload) {
                dispatch(quotesActions.updateGolfVacationItem(payload));
            },
        })
    )
);

export default enhance(Descriptions);