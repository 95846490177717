import React, { Fragment, useState } from 'react';
import { IconButton, Tooltip, Grid, Card, CardContent, Typography } from '@mui/material';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { getQuotePreview } from '../../services/quotes';
import { useClasses } from '../../config';
import styles from './styles';
import { toastr } from 'react-redux-toastr';


const PreviewQuoteTooltip = (props) => {

    const [quoteData, setQuoteData] = useState(null);
    const classes = useClasses(styles);

    const formatQuotes = (data) => {
        return data.reduce((acc, item) => {
            let occupancyText = '* based on ';
            if (item.occupancy === 'other') {
                occupancyText += item.other_occupancy;
            } else if (item.occupancy === 'golf_only') {
                occupancyText += 'golf only'
            } else {
                occupancyText += item.occupancy + ' occupancy';
            }
            acc.push(
                {
                    title: item.status == 2 ? 'Confirmed - ' + item.title : item.title,
                    show_total_price: item.show_tpp === 'on',
                    total_price: item.show_tpp === 'on' ? item.total_price * item.nr_golfers : item.total_price,
                    currency: item.currency === 'GBP' ? '£' : item.currency === 'EUR' ? '€' : '$',
                    total_price_per: item.total_price_per === 'per_couple' ? 'per_couple' : 'per golfer',
                    based_on: occupancyText,
                    arrival: item.date_of_arrival,
                    departure: item.date_of_departure,
                    nights: parseInt(item.nr_nights),
                    golfers: parseInt(item.nr_golfers),
                    non_golfers: parseInt(item.nr_non_golfers),
                    rounds: parseInt(item.nr_rounds)
                }
            );
            return acc;
        }, [])
    }

    const getQuoteData = () => {
        getQuotePreview({ id: props.id })
            .then(response => {
                if (response.status === 200) {
                    setQuoteData(formatQuotes(response.data));
                }
                else {
                    setQuoteData(null);
                    toastr.error('', `Something went wrong while trying to preview quote with id ${props.id}`);
                }
            });
    };

    return (
        <Tooltip
            onOpen={() => getQuoteData()}
            placement='left'
            PopperProps={{
                sx: {
                    '& div': {
                        padding: 0,
                        maxWidth: 1200,
                        backgroundColor: 'transparent',
                        justifyContent: 'flex-end'
                    }
                }
            }}
            title={
                <Fragment>
                    {quoteData ?
                        <Grid container>
                            {quoteData.map((item, index) => {
                                return <Fragment key={index}>
                                    <Grid item className={classes.previewCard}>
                                        <Card>
                                            <CardContent className={classes.previewContent}>
                                                <Typography className={classes.previewCardTitle} gutterBottom>
                                                    {item.title}
                                                </Typography>
                                                {item.show_total_price ?
                                                    <span className={classes.previewPrice}> {item.currency}{item.total_price}</span>
                                                    :
                                                    <Fragment>
                                                        <span className={classes.previewPrice}> {item.currency}{item.total_price}</span>
                                                        <span className={classes.previewPerGolfer}> {item.total_price_per}</span>
                                                    </Fragment>
                                                }
                                                <br />
                                                <span className={classes.previewBasedOn}> {item.based_on}</span>
                                                <ul className={classes.vacationFeatures}>
                                                    <li><b>Arrival:</b> {item.arrival}</li>
                                                    <li><b>Departure:</b> {item.departure}</li>
                                                    <li>{item.nights} {item.nights == 1 ? 'Night' : 'Nights'}, {item.rounds} {item.rounds == 1 ? 'Round' : 'Rounds'}</li>
                                                    <li>{item.golfers} {item.golfers == 1 ? 'Golfer' : 'Golfers'}</li>
                                                    {
                                                        item.non_golfers !== 0 ?
                                                            <li>{item.non_golfers} {item.non_golfers == 1 ? 'Non Golfer' : 'Non Golfers'}</li>
                                                            : <span>&nbsp;</span>
                                                    }
                                                </ul>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Fragment>
                            })}
                        </Grid>
                        :
                        <Card>
                            <CardContent className={classes.previewContent}>
                                <Typography className={classes.previewCardTitle} gutterBottom>
                                    Preview not available
                                </Typography>
                                <div className={classes.emptyCard}></div>
                            </CardContent>
                        </Card>
                    }
                </Fragment>
            }
        >
            <IconButton target='_blank' href={`http://golftripjunkie.com/group/${props.previewParam}`}>
                <WysiwygIcon sx={{color: 'black'}}/>
            </IconButton>
        </Tooltip>
    );
}

export default PreviewQuoteTooltip;