import { compose } from 'redux';
import { connect } from 'react-redux';
import { ordersActions } from '../../redux';
import Orders from './Orders';

const enhance = compose(
    connect(
        state => ({
            ordersList: state.orders.ordersList,
            totalPages: state.orders.total
        }),
        dispatch => ({
            getOrders(payload) {
                dispatch(ordersActions.getOrders(payload));
            },
            deleteOrder(payload) {
                dispatch(ordersActions.deleteOrder(payload));
            }
        })
    )
);

export default enhance(Orders);