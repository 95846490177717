const styles = theme => ({
    actionsColumn: {
        minWidth: 150,
        display: 'flex',
        justifyContent: 'center'
    },
    paidQuotesTable: {
        '& table': {
            '& th': {
                paddingLeft: 0,
                paddingRight: 0,
                lineHeight: '1rem',
                fontWeight: 600,
                paddingBottom: '0.4rem',
                '& div': {
                    whiteSpace: 'unset'
                }
            },
            '& td': {
                paddingLeft: 0,
                paddingRight: 0,
            },
            '& th:first-child': {
                paddingLeft: 10
            },
            '& td:nth-child(2), & th:nth-child(2)': {
                paddingLeft: 10,
                paddingRight: 10,
            }
        }
    },
    tableTitle: {
        padding: '10px 10px 20px 20px',
        fontWeight: '500'
    }
});

export default styles;