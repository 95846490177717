import axios from 'axios';

export const uploadCoursesPrices = payload => axios.post('api/pricing/course/upload', payload, {headers: {'content-type': 'multipart/form-data'}});

export const uploadLodgingPrices = payload => axios.post('api/pricing/lodging/upload', payload, {headers: {'content-type': 'multipart/form-data'}});

export const getCoursesPrices = payload => axios.get('api/pricing/course', {params: payload});

export const getCoursePrice = payload => axios.get(`api/pricing/course/${payload.id}`, {params: payload.params});

export const getLodgingPrice = payload => axios.get(`api/pricing/lodging/${payload.id}`, {params: payload.params});