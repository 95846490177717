import { types } from "./types";

const initialState = {
    authorized: false,
    loginFailed: false,
    successfulRegistration: false,
    registrationFailed: false
};

const user = (state = initialState, action) => {
    switch(action.type) {
        case types.LOGIN_SUCCESS:
            return { ...state, authorized: true, loginFailed: false};
        case types.LOGIN_FAILED:
            return { ...state, authorized: false, loginFailed: true};
        case types.SET_LOGIN_FAILED:
            return { ...state, loginFailed: action.payload};

        case types.REGISTRATION_SUCCESS:
            return {...state, successfulRegistration: true, registrationFailed: false }
        case types.REGISTRATION_FAILED:
            return {...state, registrationFailed: true }
        case types.SET_REGISTRATION_FAILED:
            return {...state, registrationFailed: action.payload }
        default:{
            return state;
        }
    }
};

export default user;

// forward exports
export { actions } from './actions';
export { types } from './types';