import { compose } from 'redux';
import { connect } from 'react-redux';
import { quotesActions } from '../../redux';
import TeeTimeReminder from './TeeTimeReminders';

const enhance = compose(
    connect(
        state => ({
            teeTimeReminders: state.quotes.teeTimeReminders,
        }),
        dispatch => ({
            getTeeTimeReminders() {
                dispatch(quotesActions.getTeeTimeReminders());
            }
        })
    )
);

export default enhance(TeeTimeReminder);