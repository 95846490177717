const styles = theme => ({

    orderInfoContainer: {
        marginTop: '10px !important',
        marginBottom: '20px !important',
    },
    orderInfoCardTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
        textAlign: 'center',
        minHeight: 45,
        paddingTop: 15,
        paddingBottom: 7,
        fontWeight: '900 !important',
        borderBottom: '5px solid #0c9c04',
    },
    orderInfoCard: {
        fontSize: 15,
        minHeight: 350
    },
    sectionTitle: {
        backgroundColor: theme.palette.secondary.main,
        padding: '1rem',
        width: '90%',
        borderTopRightRadius: 30,
        borderBottomRightRadius: 30,
        color: theme.palette.white,
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        width: '48%',
    },
    paperContent: {
        padding: 40,
        marginTop: 20,
    },
    autoCompleteContainer: {
        border: '2px dashed grey',
        padding: 25,
    },
    autocomplete: {
        width: '80%',
        margin: 'auto'
    },
    fieldsContainer: {
        marginTop: '0.2rem !important',
        marginBottom: '2.5rem'
    },
    paperButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    paperButton: {
        width: '20%',
        height: 40
    },
    splitAndMoveContainer: {
        marginTop: 20
    },
    existingAmountContainer: {
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.62)',
    },
    paymentIcon: {
        marginRight: 5
    },
    moveOrderFileds: {
        marginBottom: 15
    }
});

export default styles;