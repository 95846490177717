const styles = theme => ({
    container: {
        height: '90vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        minWidth: 500,
        minHeight: 500,
        padding: '25px 55px',
    },
    formHeader: {
        display: 'flex',
    },
    formAvatar: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 30,
    },
    avatar: {
        backgroundColor: '#0b0c64 !important'
    },
    membershipMessage: {
        alignSelf:'center'
    }
});

export default styles;