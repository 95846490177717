import axios from 'axios';

export const getResortPackages = () => axios.get('/api/packages');

export const getCoursesPackages = () => axios.get('api/packages/courses');

export const getLodgingsPackages = () => axios.get('api/packages/lodgings');

export const getCoursesByPackageId = payload => axios.get(`api/packages/${payload.id}/courses`);

export const getLodgingsByPackageId = payload => axios.get(`api/packages/${payload.id}/lodgings`);

export const updateCoursesOrder = payload => axios.post('api/packages/courses/reorder/', payload);

export const updateLodgingsOrder = payload => axios.post('api/packages/lodgings/reorder/', payload);

export const updateCourseMostImportantFlag = payload => axios.put(`api/packages/courses/${payload.id}/most-important`, payload.data);

export const updateLodgingMostImportantFlag = payload => axios.put(`api/packages/lodgings/${payload.id}/most-important`, payload.data);