import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@mui/material';
import { emailTemplates } from '../../config';
import RichTextEditor from '../editor';
import ConfirmDialog from './ConfirmDialog';
import { useClasses } from '../../config';
import styles from './styles';


const CustomizeEmailDialog = (props) => {

    const [dialogConfig, setDialogConfig] = useState({
        subject: '',
        content: '',
        type: '',
        isInitialized: false,
        isContentUpdated: false
    });
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const classes = useClasses(styles);
    const { open, title, email } = props;

    useEffect(() => {
        let subject;
        let content;
        const { type, name, link } = props;

        const firstNamePlaceholder = '{{user_first_name}}';
        const seoSlugPlaceholder = '{{link_url}}';

        switch (type) {
            case 'proposal':
                subject = emailTemplates.proposal.title;
                content = emailTemplates.proposal.template;
                break;
            case 'confirmation':
                subject = emailTemplates.confirmation.title;
                content = emailTemplates.confirmation.template;
                break;
            default:
                break;
        }

        if (name) {
            content = content.replaceAll(firstNamePlaceholder, name);
        }
        if (link) {
            content = content.replaceAll(seoSlugPlaceholder, `https://golftripjunkie.com/group/${link}`);
        }

        setDialogConfig((prevState) => ({
            ...prevState,
            subject: subject,
            content: content,
            type: type,
            initialContent: content
        }));

    }, []);

    const handleTextfieldChange = (event) => {
        setDialogConfig((prevState) => ({
            ...prevState,
            subject: event.target.value
        }));
    }

    const handleContentChange = (newValue) => {
        if (dialogConfig.isInitialized) {
            setDialogConfig((prevState) => ({
                ...prevState,
                content: newValue,
                isContentUpdated: true
            }));
        } else {
            setDialogConfig((prevState) => ({
                ...prevState,
                content: newValue,
                isContentUpdated: false,
                isInitialized: true
            }));
        }
    }

    const handleConfirmationDialogDisplay = () => {
        if (dialogConfig.isContentUpdated) {
            setShowConfirmationDialog(true);
        } else {
            props.cancelAction();
        }
    }

    const cancelEmailCustomization = () => {
        setShowConfirmationDialog(false);
        props.cancelAction();
    }

    const sendEmail = () => {
        props.sendAction({ email: email, subject: dialogConfig.subject, content: dialogConfig.content });
        props.cancelAction();
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={props.cancelAction}
                maxWidth={'xl'}
                disableEnforceFocus
            >
                <DialogTitle className={classes.dialogTitle}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <Fragment>
                        <div className={classes.subjectContainer}>
                            <TextField fullWidth value={dialogConfig.subject} label="Email subject" onChange={handleTextfieldChange} />
                        </div>
                        <DialogContentText >
                            <RichTextEditor initialContent={dialogConfig.initialContent} content={dialogConfig.content} handleContentChange={handleContentChange} />
                        </DialogContentText>
                    </Fragment>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleConfirmationDialogDisplay} className={classes.emailButton} color='primary'>
                        Cancel
                    </Button>
                    <Button variant='contained' onClick={sendEmail} className={classes.emailButton} color='secondary'>
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                open={showConfirmationDialog}
                text='You have already made changes. Are you sure you want to cancel?'
                okText='Cancel'
                okAction={cancelEmailCustomization}
                cancelText='Go back'
                cancelAction={() => { setShowConfirmationDialog(false) }}
            />
        </Fragment>
    );
}

export default CustomizeEmailDialog;