import { types } from "./types";

const initialState = {
    leadsList: [],
    page: 0,
    pages: 0,
    perPage: 0,
    total: 0,
};

const leads = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LEADS_SUCCESS:
            return {
                ...state, leadsList: action.payload.results,
                page: action.payload.page, pages: action.payload.pages,
                perPage: action.payload.perPage, total: action.payload.total
            }
        case types.GET_LEADS_FAILED:
            return { ...state, leadsList: [], page: 0, pages: 0, perPage: 0, total: 0 }
        case types.MARK_LEAD_AS_SPAM_SUCCESS:
            return { ...state }
        case types.MARK_LEAD_AS_SPAM_FAILED:
            return { ...state }
        case types.DELETE_LEAD_SUCCESS:
            return { ...state }
        case types.DELETE_LEAD_FAILED:
            return { ...state }
        default: {
            return state;
        }
    }
};


export default leads;

// forward exports
export { actions } from './actions';
export { types } from './types';