import { types } from "./types";

const initialState = {
    resortPackages: [],
    lodgingDropdownInfo: {},
    roomDropdownInfo: {},

    coursesPackages: [],
    lodgingsPackages: [],

    courses: []
};

const packages = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_RESORT_PACKAGES_SUCCESS:
            return {
                ...state, resortPackages: action.payload.golfPackages,
                lodgingDropdownInfo: action.payload.lodgingDropdownInfo, roomDropdownInfo: action.payload.roomDropdownInfo
            };
        case types.GET_RESORT_PACKAGES_FAILED:
            return { ...state, resortPackages: [] };
        case types.GET_COURSES_PACKAGES_SUCCESS:
            return { ...state, coursesPackages: action.payload.packages };
        case types.GET_COURSES_PACKAGES_FAILED:
            return { ...state, coursesPackages: [] };
        case types.GET_LODGINGS_PACKAGES_SUCCESS:
            return { ...state, lodgingsPackages: action.payload.packages };
        case types.GET_LODGINGS_PACKAGES_FAILED:
            return { ...state, lodgingsPackages: [] };
        case types.GET_COURSES_BY_PACKAGE_ID_SUCCESS:
            return { ...state, courses: action.payload.courses };
        case types.GET_COURSES_BY_PACKAGE_ID_FAILED:
            return { ...state, courses: [] };
        default: {
            return state;
        }
    }
};

export default packages;

// forward exports
export { actions } from './actions';
export { types } from './types';