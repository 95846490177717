export const types = {
    LOGIN: 'LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED: 'LOGIN_FAILED',
    SET_LOGIN_FAILED: 'SET_LOGIN_FAILED',

    REGISTER: 'REGISTER',
    REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
    REGISTRATION_FAILED: 'REGISTRATION_FAILED',
    SET_REGISTRATION_FAILED: 'SET_REGISTRATION_FAILED'
}