import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Register from '../containers/register';
import Login from '../containers/login';
import PrivateRoute from './PrivateRoute';
import Quotes from '../containers/quotes';
import Quote from '../containers/quotes/quote';
import TeeTimeReminder from '../containers/teeTime';
import DueQuotes from '../containers/dueQuotes';
import Leads from '../containers/leads';
import PaidQuotes from '../containers/paidQuotes';
import Orders from '../containers/orders';
import { CreateOrder, OrderInformation } from '../containers/orders/order';
import { useClasses } from '../config';
import styles from '../containers/register/styles';
import PricingSystem from '../containers/pricing';
import PackageMangement from '../containers/packageManagement';
import InProgressQuotePayments from '../containers/progressPayments';


const AppRoutes = () => {
    //FIXME: Migrate Register component to stateless component
    const registerClasses = useClasses(styles);

    return (
        <Routes>
            <Route
                path='/register'
                element={<Register classes={registerClasses} />}
            />
            <Route
                path='/login'
                element={<Login />}
            />
            <Route
                path='/quotes'
                element={<PrivateRoute><Quotes /></PrivateRoute>}
            />
            <Route
                path='/golf-vacation/:id'
                element={<PrivateRoute><Quote /></PrivateRoute>}
            />
            <Route
                path='/golf-vacation'
                element={<PrivateRoute><Quote /></PrivateRoute>}
            />
            <Route
                path='/golf-vacation-from-lead'
                element={<PrivateRoute><Quote /></PrivateRoute>}
            />
            <Route
                path='/quotes/due'
                element={<PrivateRoute><DueQuotes /></PrivateRoute>}
            />
            <Route
                path='/quotes/paid'
                element={<PrivateRoute><div><PaidQuotes /></div></PrivateRoute>}
            />
            <Route
                path='/quotes/payments-in-progress'
                element={<PrivateRoute><div><InProgressQuotePayments /></div></PrivateRoute>}
            />
            <Route
                path='/orders'
                element={<PrivateRoute><Orders /></PrivateRoute>}
            />
            <Route
                path='/order/view/:id'
                element={<PrivateRoute><OrderInformation /></PrivateRoute>}
            />
            <Route
                path='/order/create'
                element={<PrivateRoute><CreateOrder /></PrivateRoute>}
            />
            <Route
                path='/tee-time'
                element={<PrivateRoute><TeeTimeReminder /></PrivateRoute>}
            />
            <Route
                path='/sales'
                element={<PrivateRoute><div>Sales</div></PrivateRoute>}
            />
            <Route
                path='/leads'
                element={<PrivateRoute><Leads /></PrivateRoute>}
            />
            <Route
                path='/pricing-system'
                element={<PrivateRoute><PricingSystem/></PrivateRoute>}
            />
            <Route
                path='/templates'
                element={<PrivateRoute><div>Templates</div></PrivateRoute>}
            />
            <Route
                path='/manage-packages'
                element={<PrivateRoute><PackageMangement/></PrivateRoute>}
            />
            <Route
                path='*'
                element={<Navigate to='/login' replace />}
            />
        </Routes>
    );
}

export default AppRoutes;