import { compose } from 'redux';
import { connect } from 'react-redux';
import { quotesActions } from '../../redux';
import Quotes from './Quotes';

const enhance = compose(
    connect(
        state => ({
            quotesList: state.quotes.mainQuotes.quotesList,
            totalRows: state.quotes.mainQuotes.total,
            totalPages: state.quotes.mainQuotes.pages
        }),
        dispatch => ({
            getQuotes(payload) {
                dispatch(quotesActions.getQuotes(payload));
            },
            setQuoteConfirmFlag(payload) {
                dispatch(quotesActions.setQuoteConfirmFlag(payload));
            },
            setQuotePublishFlag(payload) {
                dispatch(quotesActions.setQuotePublishFlag(payload));
            },
            deleteQuote(payload) {
                dispatch(quotesActions.deleteQuote(payload));
            },
            sendFollowupEmail(payload) {
                dispatch(quotesActions.sendFollowupEmail(payload));
            },
            setFollowupFlag(payload) {
                dispatch(quotesActions.setFollowupFlag(payload));
            },
        })
    )
);

export default enhance(Quotes);