import { useEffect, useState, useCallback } from 'react';
import { useDrop } from 'react-dnd'
import { useLocation, useParams } from 'react-router-dom';
import QuoteInfo from './QuoteInfo';
import { Stack, Button } from '@mui/material';
import { useClasses } from '../../../config';
import styles from './styles';
import GolfVacationItems from './golfVacationItem/GolfVacationItems';
import { toastr } from 'react-redux-toastr';
import { buildGolfVacationItemPayload } from '../../../normalizers/GolfVacationItemNormalizer';
import { createQuoteFromLead } from './../../../services/leads';
import { useNavigate } from 'react-router-dom/dist';
import { ItemTypes } from '../../../config/ItemTypes';
import LeadInfoBox from './LeadInfoBox';


const Quote = (props) => {

    const classes = useClasses(styles);
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [leadInfo, setLeadInfo] = useState('');
    const [leadInfoBoxPosition, setLeadInfoBoxPosition] = useState({
        top: 90,
        left: window.innerWidth - 350,
        initialTop: 90
    });

    //componentDidMount
    useEffect(() => {
        if (id) {
            props.getQuoteById({ id });
            props.getResortPackages();
        } else {
            setLeadInfo('');
            props.setGolfVacationItems({ items: [] })
            props.setSelectedRooms({ selectedRooms: {} });
            props.setSelectedCourses({ selectedCourses: {} });
        }
    }, [id, location.state]);

    useEffect(() => {
        if (leadInfo) {
            const handleScroll = () => {
                setLeadInfoBoxPosition(prevState => ({ ...prevState, top: prevState.initialTop + window.scrollY }));
            };

            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            }
        }
    }, [leadInfo]);

    const handleCreateQuoteFromLead = () => {
        const { title, email, seoTitle, seoLink, quoteUnderReview, suggestionContent } = props.quoteInfo;
        const invalidQuoteMessage = invalidQuote(props.quoteInfo);
        if (invalidQuoteMessage) {
            toastr.warning('', invalidQuoteMessage);
            return;
        }
        let userId = localStorage.getItem('userId');
        let payload = {
            lead_id: location.state.lead_id,
            modified_by: userId,
            suggestion_content: suggestionContent,
            seo_title: seoTitle,
            title: title,
            seo_slug: seoLink,
            email: email,
            //TODO: check if these can be removed
            seo_keywords: '',
            seo_description: '',
            sef_id: seoLink,
            sef_url: seoLink,
            quoteUnderReview: quoteUnderReview
        }

        handleCreateQuoteFromLeadCall(payload);
    }

    const handleCreateQuoteFromLeadCall = (payload) => {
        createQuoteFromLead(payload)
            .then(response => {
                if (response.status === 200) {
                    navigate(`/golf-vacation/${response.data.id}`);
                    toastr.success('', 'The quote has been created');
                    let leadDetails = response.data.lead_details ? response.data.lead_details.replace(/<br>/g, '\n') : '';
                    setLeadInfo(cleanLeadInfoText(leadDetails));
                }
                else {
                    toastr.error('', 'Failed to create quote');
                }
            });
    }

    const cleanLeadInfoText = (text) => {
        return text.split('\n')
            .map(line => line.trim())
            .join('\n');
    }

    const moveBox = useCallback((left, top) => {
        setLeadInfoBoxPosition({ left, top, initialTop: top - window.scrollY })
    }, [leadInfoBoxPosition, setLeadInfoBoxPosition])

    const [, drop] = useDrop(
        () => ({
            accept: ItemTypes.BOX,
            drop(item, monitor) {
                const delta = monitor.getDifferenceFromInitialOffset()
                const left = Math.round(item.left + delta.x)
                const top = Math.round(item.top + delta.y)
                moveBox(left, top)
                return undefined
            },
        }),
        [moveBox],
    )

    const handleSaveOrUpdate = () => {
        const { title, email, seoTitle, seoLink, quoteUnderReview, suggestionContent } = props.quoteInfo;
        const invalidQuoteMessage = invalidQuote(props.quoteInfo);
        if (invalidQuoteMessage) {
            toastr.warning('', invalidQuoteMessage);
            return;
        }
        let payload = {
            password: props.password ? props.password : Math.random().toString(36).substring(3),
            suggestion_content: suggestionContent,
            seo_title: seoTitle,
            title: title,
            seo_slug: seoLink,
            email: email,
            deleted: [],
            //TODO: check if these can be removed
            seo_keywords: '',
            seo_description: '',
            sef_id: seoLink,
            sef_url: seoLink,
            quoteUnderReview: quoteUnderReview
        }

        let invalidItems = false;
        let normalizedItems = props.items.map((gvi, index) => {
            let item = { ...gvi };

            if (invalidItems) {
                return;
            }
            if (invalidGolfVacationItem(item)) {
                toastr.warning('', 'Please fill in all the required fields for all golf vacation items!');
                invalidItems = true;
                return;
            }

            return buildGolfVacationItemPayload(item, id, index, props.selectedCourses[item.id]);
        });

        if (invalidItems) {
            return;
        }

        payload.golf_vacation_items = normalizedItems;
        if (id) {
            props.updateQuote({ id: id, data: payload });
        } else {
            props.saveQuote(payload);
        }
    }

    const invalidQuote = (quoteInfo) => {
        const { title, email, seoLink } = quoteInfo;
        if (title === '' || email === '' || seoLink === '') {
            return 'Please fill in all the required fields!';
        }
        let emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}', 'i');
        if (email) {
            if (!emailRegex.test(email)) {
                return 'Please insert a correct email address!';
            }
        }
        return '';
    }

    const invalidGolfVacationItem = (item) => {
        const { title, first_name, last_name, date_of_arrival_and_departure } = item;
        if (title === '' || first_name === '' || last_name === '') {
            return true;
        }
        if (date_of_arrival_and_departure.length !== 2) {
            return true;
        }
        if (item.price == '' && item.price_as_deposit == false || item.price == 0 && item.price_as_deposit == false) {
            return true;
        }
        return false;
    }

    return (
        <div>
            <Stack spacing={2} ref={drop}>
                {leadInfo &&
                    <LeadInfoBox
                        left={leadInfoBoxPosition.left}
                        top={leadInfoBoxPosition.top}
                        info={leadInfo}
                        onCloseAction={() => setLeadInfo('')}
                    />
                }
                <div className={classes.sectionTitle}>General golf vacation information</div>
                <QuoteInfo
                    id={id}
                    quoteInfo={props.quoteInfo}
                    items={props.items}
                    classes={classes}
                />
                <div className={classes.sectionTitle}>Vacation Items</div>
                <div>
                    <GolfVacationItems
                        items={props.items}
                        quoteId={id}
                    />
                </div>
                <div className={classes.saveButtonContainer}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => location.state ? handleCreateQuoteFromLead() : handleSaveOrUpdate()}
                        className={classes.saveButton}
                    >Save</Button>
                    <Button
                        variant='contained'
                        color='secondary'
                        target='_blank'
                        href={`http://golftripjunkie.com/group/${props.quoteInfo.seoLink}`}
                        className={classes.previewButton}
                    >
                        Preview
                    </Button>
                </div>
            </Stack>
        </div>
    );
}

export default Quote;