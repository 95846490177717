import React, { useState } from 'react';
import { Avatar, Button, Card, TextField, Typography, InputAdornment, IconButton } from "@mui/material";
import { Link } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Logo from '../../images/gtj-logo-scroll.png'
import { useClasses } from '../../config';
import styles from './styles';


const Login = (props) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const classes = useClasses(styles);

    return (
        <div className={classes.container}>
            <Card elevation={4} className={classes.form}>
                <div className={classes.formHeader}>
                    <div className={classes.formAvatar}>
                        <Avatar className={classes.avatar}>
                            <LockIcon />
                        </Avatar>
                    </div>
                    <img src={Logo} alt='Golf Trip Junkie' width='300px' />
                </div>
                <TextField
                    label='Username'
                    value={username}
                    onChange={(event) => { setUsername(event.target.value) }}
                    fullWidth
                    required
                    autoComplete='username'
                />
                <TextField
                    autoComplete='password'
                    autoFocus
                    label='Password'
                    value={password}
                    onChange={(event) => { setPassword(event.target.value) }}
                    type={showPassword ? 'text' : 'password'}
                    required
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton onClick={() => { setShowPassword(prevState => !prevState) }}>
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Button variant='contained' color='secondary' onClick={() => { props.login({ username, password }) }}>Login</Button>
                <Typography className={classes.membershipMessage} color='grey'>
                    Not a member? <Link to='/register'>Register now</Link>
                </Typography>
            </Card>
        </div>
    );
}


export default Login;