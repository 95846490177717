import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import { leadsService } from '../services';
import {
    leadsActions,
    leadsTypes,
} from '../redux';


const getLeads = function* (action) {
    try {
        const response = yield call(leadsService.getLeads, action.payload);
        yield put(leadsActions.getLeadsSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(leadsActions.getLeadsFailed());
    }
};

const markLeadAsSpam = function* (action) {
    try {
        yield call(leadsService.markLeadAsSpam, action.payload);
        yield put(leadsActions.markLeadAsSpamSuccess());

        const leadsState = yield select((state) => state.leads);
        const leadsSearch = localStorage.getItem('leadsSearch');
        yield put(leadsActions.getLeads({ page: leadsState.page, perPage: leadsState.perPage, search: leadsSearch }));
    } catch (e) {
        console.log(e);
        yield put(leadsActions.markLeadAsSpamFailed());
    }
};

const deleteLead = function* (action) {
    try {
        yield call(leadsService.deleteLead, action.payload);
        yield put(leadsActions.deleteLeadSuccess());

        const leadsState = yield select((state) => state.leads);
        const leadsSearch = localStorage.getItem('leadsSearch');
        yield put(leadsActions.getLeads({ page: leadsState.page, perPage: leadsState.perPage, search: leadsSearch }))
    } catch (e) {
        console.log(e);
        yield put(leadsActions.deleteLeadFailed());
    }
};

export default function* leadsSaga() {
    yield all([
        takeLatest(leadsTypes.GET_LEADS, getLeads),
        takeLatest(leadsTypes.MARK_LEAD_AS_SPAM, markLeadAsSpam),
        takeLatest(leadsTypes.DELETE_LEAD, deleteLead),
    ]);
}