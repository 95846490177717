const styles = theme => ({
    table: {
        marginBottom: 30
    },
    tableTitle: {
        padding: '10px 10px 20px 20px',
        fontWeight: '500'
    }

});

export default styles;