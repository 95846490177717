import { useState, useEffect, useMemo, Fragment } from 'react';
import { MaterialReactTable } from 'material-react-table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import { PaymentsHistoryTooltip, PreviewQuoteTooltip } from '../../components/popup';
import { ConfirmDialog } from '../../components/dialog';
import QuotesTableFilters from './QuotesTableFilters';
import { useClasses } from '../../config';
import styles from './styles';
import moment from 'moment';


const Quotes = (props) => {
    const classes = useClasses(styles);
    const navigate = useNavigate();
    const [dateRangeFilter, setDateRangeFilter] = useState([]);
    const [publishedFilter, setPublishedFilter] = useState('');
    const [confirmedFilter, setConfirmedFilter] = useState('');
    const [typeFilter, setTypeFilter] = useState([]);
    const [typeFilterMenuOpen, setTypeFilterMenuOpen] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedQuoteId, setSelectedQuoteId] = useState(0);
    const [globalFilter, setGlobalFilter] = useState('');
    const [columnFilters, setColumnFilters] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    //componentWillMount
    useEffect(() => {
        const globalSearch = localStorage.getItem('quotesSearch');
        const publishedFilterValue = localStorage.getItem('quotesPublishedFilter');
        const confirmedFilterValue = localStorage.getItem('quotesConfirmedFilter');
        let dateRangeFilterValue = localStorage.getItem('quotesDateRangeFilter');
        dateRangeFilterValue = dateRangeFilterValue ? JSON.parse(dateRangeFilterValue).map(e => new Date(e)) : [];
        let typeFilterValue = localStorage.getItem('quotesTypeFilter');
        typeFilterValue = typeFilterValue ? JSON.parse(typeFilterValue) : [];

        const perPage = localStorage.getItem('quotesPageSize');
        if (perPage) {
            setPagination(prevState => ({ ...prevState, pageSize: perPage }));
        }
        if (globalSearch) {
            setGlobalFilter(globalSearch);
        }
        setDateRangeFilter(dateRangeFilterValue);
        setTypeFilter(typeFilterValue);
        setPublishedFilter(publishedFilterValue);
        setConfirmedFilter(confirmedFilterValue);
    }, []);

    useEffect(() => {
        let params = buildQueryParameters();
        props.getQuotes(params);
    },
        [
            columnFilters,
            globalFilter,
            publishedFilter,
            confirmedFilter,
            typeFilter,
            dateRangeFilter,
            pagination.pageIndex,
            pagination.pageSize
        ]);

    useEffect(() => {
        localStorage.setItem('quotesPageSize', pagination.pageSize);
    }, [pagination.pageSize]);

    const buildQueryParameters = () => {
        const { pageIndex, pageSize } = pagination;
        let params = { page: pageIndex + 1, perPage: pageSize, search: globalFilter }

        if (typeFilter.includes('Course')) {
            params.courses = true;
        }

        if (typeFilter.includes('Lodging')) {
            params.lodgings = true;
        }

        if (dateRangeFilter.length > 1) {
            params.startDate = moment(dateRangeFilter[0]).format('YYYY-MM-DD')
            params.endDate = moment(dateRangeFilter[1]).format('YYYY-MM-DD')
        }

        if (publishedFilter) {
            params.published = publishedFilter === 'published' ? true : false;
        }

        if (confirmedFilter) {
            params.confirmed = confirmedFilter === 'confirmed' ? true : false;
        }

        return params;
    }

    const handlePublishFlagChange = (cell) => {
        let toggleVaue = cell.getValue() == 0 ? 1 : 0;
        let payload = { id: cell.row.original.id, flag: toggleVaue };
        props.setQuotePublishFlag(payload);
    }

    const handleConfirmFlagChange = (cell) => {
        let toggleVaue = cell.getValue() == 0 ? 1 : 0;
        let payload = { id: cell.row.original.id, flag: toggleVaue };
        props.setQuoteConfirmFlag(payload);
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                size: 50
            },
            {
                accessorKey: 'title',
                header: 'Title',
                size: 200,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'published',
                header: 'Published',
                filterVariant: 'checkbox',
                Cell: ({ cell }) => (
                    <IconButton onClick={() => handlePublishFlagChange(cell)}>
                        {cell.getValue() == 0 ? <CloseIcon sx={{ color: 'black' }} /> : <CheckIcon sx={{ color: 'black' }} />}
                    </IconButton>

                ),
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'confirmed',
                header: 'Confirmed',
                filterVariant: 'checkbox',
                Cell: ({ cell }) => (
                    <IconButton onClick={() => handleConfirmFlagChange(cell)}>
                        {cell.getValue() == 0 ? <CloseIcon sx={{ color: 'black' }} /> : <CheckIcon sx={{ color: 'black' }} />}
                    </IconButton>

                ),
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'follow_up_1',
                header: 'Follow up',
                filterVariant: 'checkbox',
                Cell: ({ cell }) => (
                    cell.getValue() == 0 ?
                        <IconButton onClick={() => props.setFollowupFlag({ id: cell.row.original.id, body: { value: 1 } })}>
                            <CloseIcon sx={{ color: 'black' }} />
                        </IconButton>
                        : cell.getValue() == 1 ?
                            <IconButton onClick={() => props.setFollowupFlag({ id: cell.row.original.id, body: { value: 0 } })}>
                                <CheckIcon sx={{ color: 'black' }} />
                            </IconButton>
                            : 'Not Applicable'
                ),
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
        ],
        [],
    );

    const openDeleteDialog = (id) => {
        setSelectedQuoteId(id);
        setShowDeleteDialog(true);
    }

    const performDeleteAction = () => {
        deleteQuote({ id: selectedQuoteId, section: 'MAIN' });
        setShowDeleteDialog(false);
    }

    const handleGlobalFilterChange = (value) => {
        let searchValue = value == undefined ? '' : value;
        localStorage.setItem('quotesSearch', searchValue);
        setGlobalFilter(searchValue);
    }

    const handlePublishedFilterChange = (event) => {
        let publishedFilterValue = event.target.value === publishedFilter ? '' : event.target.value;
        localStorage.setItem('quotesPublishedFilter', publishedFilterValue);
        setPublishedFilter(publishedFilterValue);
    }

    const handleConfirmedFilterChange = (event) => {
        let confirmedFilterValue = event.target.value === confirmedFilter ? '' : event.target.value;
        localStorage.setItem('quotesConfirmedFilter', confirmedFilterValue);
        setConfirmedFilter(confirmedFilterValue);
    }

    const handleDateRangeChange = (value) => {
        let dateRangeValue = value ? value : [];
        localStorage.setItem('quotesDateRangeFilter', JSON.stringify(dateRangeValue));
        setDateRangeFilter(dateRangeValue);
    }

    const handleTypeFilterDelete = (valueToDelete) => {    
        const updatedTypeFilter = typeFilter.filter((type) => type !== valueToDelete);
        setTypeFilter(updatedTypeFilter);
        localStorage.setItem('quotesTypeFilter', JSON.stringify(updatedTypeFilter));
    };

    const handleTypeFilterChange = (event) => {
        const value = event.target.value;
        // On autofill we get a stringified value.
        const typeFilterValue = typeof value === 'string' ? value.split(',') : value;
        localStorage.setItem('quotesTypeFilter', JSON.stringify(typeFilterValue));
        setTypeFilter(typeFilterValue);
        setTypeFilterMenuOpen(false);
    };

    const resetFilters = () => {
        setPublishedFilter('');
        setConfirmedFilter('');
        setDateRangeFilter([]);
        setTypeFilter([]);

        localStorage.removeItem('quotesPublishedFilter');
        localStorage.removeItem('quotesConfirmedFilter');
        localStorage.removeItem('quotesDateRangeFilter');
        localStorage.removeItem('quotesTypeFilter');
    }

    const { quotesList, totalRows, totalPages, deleteQuote } = props;
    return (
        <div className='quotesSection mainSection'>
            <MaterialReactTable
                columns={columns}
                data={quotesList}
                initialState={{
                    showGlobalFilter: true
                }}
                state={{
                    columnFilters,
                    pagination
                }}
                defaultColumn={{ size: 70 }}
                autoResetPageIndex={false}
                manualPagination
                manualFiltering
                onPaginationChange={setPagination}
                rowCount={totalRows}
                muiPaginationProps={{
                    rowsPerPageOptions: [10, 50, 100, 200]
                }}
                //pageCount={totalPages}
                enableGlobalFilter={false}
                onColumnFiltersChange={setColumnFilters}
                enableRowActions
                positionActionsColumn='last'
                muiTablePaperProps={{ elevation: 12 }}
                muiTopToolbarProps={{
                    sx: {
                        width: '100%',
                        '& div': {
                            width: '100%',
                        },
                    }
                }}
                muiBottomToolbarProps={{
                    sx: {
                        zIndex: 0
                    }
                }}
                renderRowActions={({ row, table }) => (
                    <div className={classes.actionButtons}>
                        <Tooltip title="Edit">
                            <IconButton onClick={() => { navigate(`/golf-vacation/${row.original.id}`) }}>
                                <EditIcon sx={{ color: 'black' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton onClick={() => { openDeleteDialog(row.original.id) }}>
                                <DeleteIcon sx={{ color: 'black' }} />
                            </IconButton>
                        </Tooltip>
                        <PreviewQuoteTooltip id={row.original.id} previewParam={row.original.sef_url} />
                        {
                            row.original.confirmed == 0 && row.original.follow_up_1 == 0 &&
                            <Fragment>
                                <Tooltip title="Send follow up email">
                                    <IconButton onClick={() => { props.sendFollowupEmail({ id: row.original.id }) }}>
                                        <EmailIcon sx={{ color: 'black' }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Stop follow up process">
                                    <IconButton onClick={() => props.setFollowupFlag({ id: row.original.id, body: { value: 2 } })}>
                                        <DangerousIcon sx={{ color: 'black' }} />
                                    </IconButton>
                                </Tooltip>
                            </Fragment>
                        }
                        {row.original.hasPayments && <PaymentsHistoryTooltip id={row.original.id} />}

                    </div>
                )}
                renderToolbarInternalActions={() =>
                    <QuotesTableFilters
                        classes={classes}
                        globalFilter={globalFilter}
                        handleGlobalFilterChange={handleGlobalFilterChange}
                        typeFilter={typeFilter}
                        typeFilterMenuOpen={typeFilterMenuOpen}
                        setTypeFilterMenuOpen={setTypeFilterMenuOpen}
                        handleTypeFilterChange={handleTypeFilterChange}
                        handleTypeFilterDelete={handleTypeFilterDelete}
                        dateRangeFilter={dateRangeFilter}
                        handleDateRangeChange={handleDateRangeChange}
                        publishedFilter={publishedFilter}
                        handlePublishedFilterChange={handlePublishedFilterChange}
                        confirmedFilter={confirmedFilter}
                        handleConfirmedFilterChange={handleConfirmedFilterChange}
                        resetFilters={resetFilters}
                    />
                }
            />
            <ConfirmDialog
                open={showDeleteDialog}
                text='Are you sure you want to delete this quote?'
                okText='Yes'
                okAction={performDeleteAction}
                cancelText='No'
                cancelAction={() => { setShowDeleteDialog(false) }}
            />
        </div>
    );
}

export default Quotes;