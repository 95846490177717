const styles = theme => ({
    paymentsTable: {
        width: '100%'
    },
    paymentsTableCell: {
        align: 'center'
    },
    paymentsContainer: {
        marginTop: '20px !important',
        width: '100%',
        marginBottom: 30
    },
    paymentCardTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
        textAlign: 'center',
        minHeight: 45,
        paddingTop: 15,
        paddingBottom: 7,
        fontWeight: '900 !important'
    },
    paymentCard: {
        minWidth: 280
    },
    paymentsList: {
        paddingTop: '0 !important'
    },
    accountPayment: {
        backgroundColor: `${theme.palette.grey.table} !important`,
    },
    accountPaymentText: {
        textTransform: 'capitalize',
    },
    individualPayment: {
        paddingTop: '3px !important',
    },
    paymentIcon: {
        paddingRight: 3,
        margin: '0 5px 0 15px',
        color: theme.palette.grey.icon,
    },
});

export default styles;