const styles = theme => ({
    sectionTitle: {
        backgroundColor: theme.palette.secondary.main,
        padding: '0.8rem',
        width: '90%',
        borderTopRightRadius: 30,
        borderBottomRightRadius: 30,
        color: theme.palette.white,
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1
    },
    generalInfoInput: {
        paddingRight: '15px !important'
    },
    titleAndEmailTextfields: {
        paddingTop: '2px !important'
    },
    golfVacationItem: {
        display: 'flex',
        padding: 12,
        marginBottom: 6,
        backgroundColor: '#dde0dc',
        borderRadius: 10,
        cursor: 'pointer'
    },
    golfVacationTitle: {
        flex: 1
    },
    golfVacationDeleteIcon: {
        padding: '0 !important',
        marginRight: '5px !important'
    },
    golfVacationIcon: {
        padding: '0 !important',
        marginRight: '12px !important'
    },
    golfVacationItemSection: {
        padding: 8,
        backgroundColor: '#d7edff',
        marginBottom: 20,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.62)',
        fontSize: '1.1rem',
        letterSpacing: '.04 rem',
        lineHeight: '2rem',
    },
    dateRangeContainer: {
        display: 'inline-flex',
        flexDirection: 'column'
    },
    dateRangeInput: {
        position: 'relative',
        top: '-23px',
        left:0,
        height: 56,
        borderColor: 'rgba(0,0,0,0.23)',
        '& div': {
            borderRadius: 4,
            borderColor: 'rgba(0,0,0,0.23)',
        }
    },
    dateRangeLabel: {
        width: 'max-content',
        backgroundColor: '#ffffff',
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: '1rem',
        lineHeight: '1.4375em',
        position: 'relative',
        display: 'block',
        transformOrigin: 'top left',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        left: 0,
        top: 0,
        transform: 'translate(14px, -7px) scale(0.75)',
        zIndex: 1,
    },
    saveButtonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        position: '-webkit-sticky',
        position: 'sticky',
        bottom: 0
    },
    saveButton: {
        minWidth: '150px !important',
        height: 40, 
        width: '85%',
    },
    previewButton: {
        width: '14%',
    },
    emailSection: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginBottom: 20,
        width: '80%'
    },
    reviewEmailSection: {
        display: 'flex'
    },
    reviewText: {
        marginTop: 8
    }
});

export default styles;