const styles = theme => ({
    previewCard: {
        margin: '5px 5px 0 0 !important',
        minWidth: 280,
        width: 280,
    },
    emptyCard: {
        minHeight: 100,
        height: 100,
        minWidth: 200,
        width: 200
    },
    previewCardTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.white,
        textAlign: 'center',
        minHeight: 55,
        paddingTop: 15,
        paddingBottom: 7,
        fontWeight: '900 !important'
    },
    previewContent: {
        padding: '0 !important',
        backgroundColor: 'white !important',
    },
    previewPrice: {
        marginLeft: '30%',
        fontSize: 22,
        color: theme.palette.secondary.dark,
        fontWeight: 'bold',
        paddingTop: 30,
        textAlign: 'center',
    },
    previewPerGolfer: {
        fontSize: 14
    },
    previewBasedOn: {
        display: 'block',
        marginLeft: '28%',
        marginTop: '-5px'
    },
    vacationFeatures: {
        padding: '10px 0 5px 20px',
        listStyleType: 'none',
        marginBottom: 0,
        '& li': {
            fontSize: 14,
            '&:before': {
                content: '"\u2713"',
                float: 'left',
                marginRight: 5,
                fontWeight: 'bold',
            }
        }
    },
    golfVacationItemSection: {
        padding: 8,
        backgroundColor: '#d5e2ed',
        marginBottom: 20,
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.62)',
        fontSize: '1.1rem',
        letterSpacing: '.04 rem',
        lineHeight: '2rem',
    },
    paymentsTooltipContent: {
        padding: '30px !important'
    }
});

export default styles;