import axios from 'axios';

export const saveOrder = payload => axios.post('api/orders/insert', payload);

export const getOrderById = payload => axios.get(`api/orders/${payload.id}`);

export const deleteOrder = payload => axios.delete(`api/orders/${payload.id}`);

export const getOrders = payload => axios.get('api/orders', { params: payload });

export const getOrderProducts = payload => axios.get('api/orders/products/search', { params: payload });

export const getUsersByEmail = payload => axios.get('api/orders/users/search', { params: payload });

export const getUsersByProduct = payload => axios.get(`api/orders/product/${payload.id}/users`);

export const splitOrder = payload => axios.post(`api/orders/split/${payload.orderId}`, payload);

export const moveOrder = payload => axios.post(`api/orders/move/${payload.orderId}/item/${payload.toVacationItemId}`, payload.data);