import axios from 'axios';

export const sendConfirmationPdf = payload => axios.post(`api/emails/send/confirmations/${payload.id}`);

export const sendVoucher = payload => axios.post(`api/emails/send/vouchers/${payload.id}`);

export const sendVouchers = payload => axios.post(`api/emails/send/confirmations/${payload.id}`);

export const sendPaymentReminderEmail = payload => axios.post(`api/emails/send/payment/${payload.id}`);

export const sendPreTripFollowupEmail = payload => axios.post(`api/emails/send/pre/followup/${payload.id}`);

export const sendFollowupEmail = payload => axios.post(`api/emails/send/followup/${payload.id}`);

export const sendAfterTripFollowupEmail = payload => axios.post(`api/emails/send/after/followup/${payload.id}`);

export const sendThankYouEmail = payload => axios.post(`api/emails/send/thankyou/${payload.id}`);

export const sendGenericEmail = payload => axios.post('api/emails/send/generic', payload);

export const sendConfirmationEmail = payload => axios.post(`api/emails/send/quote/${payload.id}/confirmation`, payload.body);
