import React, { useEffect, useState, useMemo, memo, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Grid, TextField, List, ListItemButton, ListItemText, Collapse, Tooltip,
    Divider, IconButton, TableContainer, TableHead, TableRow, TableCell, Paper, Table, TableBody, InputLabel
} from '@mui/material';
import { useClasses } from '../../../../../config';
import styles from './styles';
import { DateRangePicker } from '@wojtekmaj/react-daterange-picker';
import { dateForBackend, formatDateForRange } from '../../../../../config/utils';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BedIcon from '@mui/icons-material/Bed';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { quotesActions } from '../../../../../redux';
import { toastr } from 'react-redux-toastr';
import { GeneralAutocomplete } from '../../../../../components/autocomplete';
import { getLodgingPrice } from '../../../../../services/pricing';
import Select from 'react-select';

const Lodging = (props) => {

    const classes = useClasses(styles);
    const [search, setSearch] = useState('');
    const [date, setDate] = useState([]);
    const [units, setUnits] = useState('');
    const [cost, setCost] = useState('');
    const [markup, setMarkup] = useState('');
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const { contentBox, itemId, itemPackage, updatePackage, updateItem, resortPackages, selectedRooms, nrGolfers } = props;

    useEffect(() => {
        setDate(props.itemDate);
    }, [props.itemDate]);

    const displayRangeValue = () => {
        if (date && date.length === 2) {
            return `${formatDateForRange(date[0])}  →  ${formatDateForRange(date[1])}`;
        } else {
            return 'Choose dates';
        }
    }

    let selectedRoomsAvailable = selectedRooms && Object.keys(selectedRooms).length !== 0 && itemId in selectedRooms;

    const totals = selectedRoomsAvailable ? selectedRooms[itemId].reduce((acc, item) => {
        let cost = parseFloat(item.cost || 0);
        let markup = parseFloat(item.markup || 0);
        let units = parseFloat(item.units || 0);
        let nights = parseFloat(item.nights || 0);

        acc.total = acc.total + (cost + cost * markup / 100);
        acc.units = acc.units + units;
        acc.nights = acc.nights + nights;
        acc.markup = acc.markup + (cost * markup / 100);
        acc.withoutMarkup = acc.withoutMarkup + cost;

        return acc;
    }, { total: 0, units: 0, nights: 0, markup: 0, withoutMarkup: 0 })
        : { total: 0, units: 0, nights: 0, markup: 0, withoutMarkup: 0 };

    const getTotalPrice = (cost, markup) => {
        const total = parseFloat(cost) + parseFloat(markup) / 100 * parseFloat(cost);
        return total.toFixed(2);
    }

    const columns = useMemo(
        () => ['Package', 'Lodging', 'Title', 'Nights', 'Units', 'Cost', 'Markup', 'Price', ''], []
    );

    const totalsColumns = useMemo(
        () => ['Total', 'Without markup', 'Markup', 'Units', 'Nights'], []
    );

    const addLodging = (resort, room) => {
        if (units == 0 || date.length !== 2) {
            toastr.error('', 'Make sure that the date and units are entered correctly!');
            return;
        }
        const startDate = moment(date[0]);
        const endDate = moment(date[1]);
        const nights = endDate.diff(startDate, 'days');

        let newEntry = {
            id: 0,
            vacation_item_id: itemId,
            lodge_id: resort.value,
            lodge_title: resort.label,
            date_of_arrival: dateForBackend(date[0]),
            date_of_departure: dateForBackend(date[1]),
            price: cost ? cost : '0',
            // should I send the occupancy?
            occupancy: null,
            occupancy_other: null,
            lodge_type_id: room.value,
            lodge_type_title: room.label,
            no_units: units,
            no_nights: nights,
            no_golfers: nrGolfers,
            markup: markup ? markup : '0'
        }

        props.addSelectedRoom({ newEntry: newEntry, itemId: itemId, package: { value: itemPackage.value, label: itemPackage.label } });
        setUnits('');
        setMarkup('');
        setCost('');
    }

    const handleUpdateRoom = (room) => {
        let payload = {
            id: room.internal_id,
            vacation_item_id: itemId,
            lodge_id: room.lodging.value,
            lodge_title: room.lodging.label,
            date_of_arrival: room.date_of_arrival,
            date_of_departure: room.date_of_departure,
            price: room.cost,
            // should I send the occupancy?
            occupancy: null,
            occupancy_other: null,
            lodge_type_id: room.value,
            lodge_type_title: room.label,
            no_units: room.units,
            no_nights: room.nights,
            markup: room.markup
        }

        props.saveSelectedRoom({ itemId: itemId, id: room.internal_id, body: payload })
    }

    const changeTitleAndUpdatePrice = (room, value) => {
        // console.log(room);
        // console.log(value);
        let noRateRoom = { ...room, cost: 0, markup: 0, value: value.value, label: value.label, roomTypeOpen: false };
        let params = { lodgeId: room.lodging.value, nrPersons: nrGolfers, nrNights: room.nights, nrUnits: room.units, date: dateForBackend(date[0]) };

        getLodgingPrice({ id: value.value, params: params })
            .then(response => {
                if (response.status === 200) {
                    let rate = response.data;
                    if (rate.ratePerPerson) {
                        let updatedRoom = { ...room, cost: rate.ratePerPerson, markup: rate.markup, value: value.value, label: value.label, roomTypeOpen: false };
                        props.replaceSelectedRoom({ itemId: itemId, id: room.internal_id, room: updatedRoom });
                        handleUpdateRoom(updatedRoom);
                    } else {
                        console.log('No rate received');
                        props.replaceSelectedRoom({ itemId: itemId, id: room.internal_id, room: noRateRoom });
                        handleUpdateRoom(noRateRoom);
                    }
                }
                else {
                    toastr.error('', 'Could not retrieve new price');
                    props.replaceSelectedRoom({ itemId: itemId, id: room.internal_id, room: noRateRoom });
                    handleUpdateRoom(noRateRoom);
                }
            });
    }

    const changeNoNightsAndUpdatePrice = (room, value) => {
        // console.log(room);
        // console.log(value);
        let params = { lodgeId: room.lodging.value, nrPersons: nrGolfers, nrNights: value, nrUnits: room.units, date: dateForBackend(date[0]) };

        if (!value) {
            return;
        }

        getLodgingPrice({ id: room.value, params: params })
            .then(response => {
                if (response.status === 200) {
                    let rate = response.data;
                    if (rate.ratePerPerson && rate.ratePerPerson != 0) {
                        let updatedRoom = { ...room, cost: rate.ratePerPerson, markup: rate.markup };
                        props.replaceSelectedRoom({ itemId: itemId, id: room.internal_id, room: updatedRoom });
                        handleUpdateRoom(updatedRoom);
                    } else {
                        console.log('No rate received');
                    }
                }
                else {
                    toastr.error('', 'Could not retrieve new price');
                }
            });
    }

    const changeNoUnitsAndUpdatePrice = (room, value) => {
        // console.log(room);
        // console.log(value);
        let params = { lodgeId: room.lodging.value, nrPersons: nrGolfers, nrNights: room.nights, nrUnits: value, date: dateForBackend(date[0]) };

        if (!value) {
            return;
        }

        getLodgingPrice({ id: room.value, params: params })
            .then(response => {
                if (response.status === 200) {
                    let rate = response.data;
                    if (rate.ratePerPerson && rate.ratePerPerson != 0) {
                        let updatedRoom = { ...room, cost: rate.ratePerPerson, markup: rate.markup };
                        props.replaceSelectedRoom({ itemId: itemId, id: room.internal_id, room: updatedRoom });
                        handleUpdateRoom(updatedRoom);
                    } else {
                        console.log('No rate received');
                    }
                }
                else {
                    toastr.error('', 'Could not retrieve new price');
                }
            });
    }

    const resetPrice = (room) => {
        let updatedRoom = { ...room, cost: 0, markup: 0, units: 0 };
        props.replaceSelectedRoom({ itemId: itemId, id: room.internal_id, room: updatedRoom });
        handleUpdateRoom(updatedRoom);
    }

    const filteredLodgings = useMemo(() => {
        let lowerCaseSearch = search.toLowerCase();
        if (itemPackage && search !== '') {
            return [...itemPackage.lodgings].reduce((acc, item) => {
                if (item.label.toLowerCase().includes(lowerCaseSearch)) {
                    acc.push(item);
                } else {
                    let matchingRooms = item.children.filter(room => room.label.toLowerCase().includes(lowerCaseSearch));
                    if (matchingRooms.length > 0) {
                        acc.push({ ...item, children: matchingRooms });
                    }
                }
                return acc;
            }, []);
        }
        if (itemPackage) {
            let sortedLodgings = JSON.parse(JSON.stringify(itemPackage.lodgings));
            sortedLodgings.forEach(item => {
                item.children.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }));
            });

            return sortedLodgings;
        }
        return [];
    }, [search, itemPackage]);

    const isRoomSelected = useMemo(() => {
        return (label) => {
            if (selectedRoomsAvailable) {
                return selectedRooms[itemId].some(e => e.label === label);
            }
            return false;
        };
    }, [selectedRoomsAvailable, selectedRooms[itemId], itemId]);

    const memoizedRoomColors = useMemo(() => {
        const colors = {};
        selectedRooms[itemId].forEach(room => {
            colors[room.label] = isRoomSelected(room.label) ? 'blue' : 'black';
        });
        return colors;
    }, [isRoomSelected, selectedRooms[itemId]]);

    useEffect(() => {
        if (filteredLodgings.length === 1) {
            setSelectedIndex(0);
        }
    }, [filteredLodgings.length]);

    return (
        <Grid container className={classes.lodgingContainer}>
            <Grid item xs={4} className={classes.lodgingLeftContainer}>
                <div className={classes.card}>
                    <div className={classes.dateRangeContainer}>
                        <span className={classes.dateRangeLabel}>From/To</span>
                        <DateRangePicker
                            locale={'en-US'}
                            value={date}
                            onChange={(value) => setDate(value)}
                            className={classes.dateRangeInput}
                            calendarClassName={classes.dateRangeCalendar}
                            calendarIcon={displayRangeValue}
                            clearIcon={null}
                        />
                    </div>
                    <div className={classes.unitsAndCostContainer}>
                        <TextField
                            label='Units'
                            value={units}
                            onChange={(e) => setUnits(e.target.value)}
                            className={classes.unitsAndCostField}
                            required
                            size='small'
                            type='number'
                        />
                        <TextField
                            label='Cost'
                            value={cost}
                            onChange={(e) => setCost(e.target.value)}
                            className={classes.unitsAndCostField}
                            size='small'
                            type='number'
                        />
                        <TextField
                            label='Markup'
                            value={markup}
                            onChange={(e) => setMarkup(e.target.value)}
                            size='small'
                            type='number'
                        />
                    </div>
                    <TextField
                        label='Lodging Content Box'
                        value={contentBox}
                        onChange={(e) => updateItem({ target: 'content_box_lodging', value: e.target.value, id: itemId })}
                        fullWidth
                        size='small'
                        className={classes.contentBox}
                        multiline={true}
                    />
                    <div className={classes.fieldAndIconContainer}>
                        <GeneralAutocomplete
                            label='Package'
                            options={resortPackages}
                            value={itemPackage}
                            isLoading={resortPackages.length === 0}
                            handleChange={(value) => updatePackage(value)}
                            optionKey='label'
                        />
                        <IconButton onClick={() => updatePackage(null)}><RefreshIcon sx={{ color: 'black' }} /></IconButton>
                    </div>

                    {
                        itemPackage &&
                        <TextField
                            label='Search'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            size='small'
                            fullWidth
                        />
                    }

                    <List className={classes.overflowContainer}>
                        {itemPackage && filteredLodgings.map((resort, index) => {
                            return <Fragment key={index}>
                                <ListItemButton
                                    className={selectedIndex === index ? classes.selectedResort : classes.resort}
                                    selected={selectedIndex === index}
                                    onClick={() => setSelectedIndex((prevState) => (prevState === index ? -1 : index))}
                                >
                                    <ListItemText primary={resort.label} className={classes.resortText} />
                                    {selectedIndex === index ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={selectedIndex === index}>
                                    <List component='div' disablePadding>
                                        {resort.children.map((room, index) => {
                                            return <ListItemButton
                                                onClick={() => addLodging(resort, room)}
                                                key={index}
                                                className={classes.room}
                                            >
                                                <BedIcon className={classes.bedIcon} />
                                                <ListItemText
                                                    sx={{ color: memoizedRoomColors[room.label] }}
                                                    primary={room.label} />
                                                <Tooltip title={room.description}>
                                                    <IconButton><NotListedLocationIcon sx={{ color: 'black' }} /></IconButton>
                                                </Tooltip>
                                            </ListItemButton>
                                        })}
                                    </List>
                                </Collapse>
                                <Divider />
                            </Fragment>
                        })}
                    </List>
                </div>
            </Grid>
            <Grid item xs={8}>
                <TableContainer component={Paper} sx={{ overflow: 'visible' }}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableHead}>
                                {columns.map(col => <TableCell align='center' key={col}>{col}</TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectedRoomsAvailable && selectedRooms[itemId].map((room, index) => {
                                let selectedLodgings = props.lodgingDropdownInfo[room.package.value] ? props.lodgingDropdownInfo[room.package.value] : [];
                                let roomTypes = props.roomDropdownInfo[room.lodging.value] ? props.roomDropdownInfo[room.lodging.value] : [];

                                return <TableRow key={room.internal_id}>
                                    <TableCell className={classes.titleColumns} align='center'>
                                        {room.packageOpen ?
                                            <Fragment>
                                                <InputLabel
                                                    className={classes.closeLabel}
                                                    onClick={() => props.updateSelectedRoom({ itemId: itemId, id: room.internal_id, target: 'packageOpen', value: false })}
                                                >Close</InputLabel>
                                                <Select
                                                    value={room.package}
                                                    onChange={(value) => props.replaceSelectedRoom({ itemId: itemId, id: room.internal_id, room: { ...room, package: value, packageOpen: false } })}
                                                    options={resortPackages}
                                                    searchable={true}
                                                />
                                            </Fragment>
                                            :
                                            <span onClick={() => props.updateSelectedRoom({ itemId: itemId, id: room.internal_id, target: 'packageOpen', value: true })}>
                                                {room.package.label}
                                            </span>
                                        }
                                    </TableCell>
                                    <TableCell className={classes.titleColumns} align='center'>
                                        {room.lodgingOpen ?
                                            <Fragment>
                                                <InputLabel
                                                    className={classes.closeLabel}
                                                    onClick={() => props.updateSelectedRoom({ itemId: itemId, id: room.internal_id, target: 'lodgingOpen', value: false })}
                                                >Close</InputLabel>
                                                <Select
                                                    value={room.lodging}
                                                    onChange={(value) => {
                                                        props.replaceSelectedRoom({ itemId: itemId, id: room.internal_id, room: { ...room, lodging: value, lodgingOpen: false } });
                                                        handleUpdateRoom({ ...room, lodging: value });
                                                    }}
                                                    options={selectedLodgings}
                                                    searchable={true}
                                                />
                                            </Fragment>
                                            :
                                            <span onClick={() => props.updateSelectedRoom({ itemId: itemId, id: room.internal_id, target: 'lodgingOpen', value: true })}>
                                                {room.lodging.label}
                                            </span>
                                        }
                                    </TableCell>
                                    <TableCell className={classes.titleColumns} align='center'>
                                        {room.roomTypeOpen ?
                                            <Fragment>
                                                <InputLabel
                                                    className={classes.closeLabel}
                                                    onClick={() => props.updateSelectedRoom({ itemId: itemId, id: room.internal_id, target: 'roomTypeOpen', value: false })}
                                                >Close</InputLabel>
                                                <Select
                                                    value={room}
                                                    onChange={(value) => changeTitleAndUpdatePrice(room, value)}
                                                    options={roomTypes}
                                                    searchable={true}
                                                />
                                            </Fragment>
                                            :
                                            <span onClick={() => props.updateSelectedRoom({ itemId: itemId, id: room.internal_id, target: 'roomTypeOpen', value: true })}>
                                                {room.label}
                                            </span>
                                        }
                                    </TableCell>
                                    <TableCell align='center' className={classes.textfieldColumns}>
                                        <TextField
                                            value={room.nights}
                                            onBlur={(e) => changeNoNightsAndUpdatePrice(room, e.target.value)}
                                            onChange={(e) => {
                                                props.updateSelectedRoom({ itemId: itemId, id: room.internal_id, target: 'nights', value: e.target.value });
                                                handleUpdateRoom({ ...room, nights: e.target.value });
                                            }}
                                            size='small'
                                            className={classes.tableTextfield}
                                        />
                                    </TableCell>
                                    <TableCell align='center' className={classes.textfieldColumns}>
                                        <TextField
                                            value={room.units}
                                            onBlur={(e) => changeNoUnitsAndUpdatePrice(room, e.target.value)}
                                            onChange={(e) => {
                                                props.updateSelectedRoom({ itemId: itemId, id: room.internal_id, target: 'units', value: e.target.value });
                                                handleUpdateRoom({ ...room, units: e.target.value });
                                            }}
                                            size='small'
                                            className={classes.tableTextfield}
                                        />
                                    </TableCell>
                                    <TableCell align='center' className={classes.textfieldColumns}>
                                        <TextField
                                            value={room.cost}
                                            onChange={(e) => {
                                                props.updateSelectedRoom({ itemId: itemId, id: room.internal_id, target: 'cost', value: e.target.value });
                                                handleUpdateRoom({ ...room, cost: e.target.value === '' ? 0 : e.target.value });
                                            }}
                                            size='small'
                                            className={classes.tableTextfield}
                                            error={room.markup > 0 && room.cost == 0}
                                        />
                                    </TableCell>
                                    <TableCell align='center' className={classes.textfieldColumns}>
                                        <TextField
                                            value={room.markup}
                                            onChange={(e) => {
                                                props.updateSelectedRoom({ itemId: itemId, id: room.internal_id, target: 'markup', value: e.target.value })
                                                handleUpdateRoom({ ...room, markup: e.target.value === '' ? 0 : e.target.value });
                                            }}
                                            size='small'
                                            className={classes.tableTextfield}
                                            error={room.cost > 0 && room.markup == 0}
                                        />
                                    </TableCell>
                                    <TableCell align='center' className={classes.textfieldColumns}>
                                        <div>
                                            <div>{getTotalPrice(room.cost, room.markup)}</div>
                                            <IconButton onClick={() => resetPrice(room)}>
                                                <DoDisturbIcon sx={{ color: 'black' }} />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.textfieldColumns}>
                                        <IconButton onClick={() => props.deleteSelectedRoom({ itemId: itemId, id: room.internal_id })}>
                                            <DeleteIcon sx={{ color: 'black' }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper} className={classes.totalsTable}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableHead}>
                                {totalsColumns.map(column => <TableCell align='center' key={column}>{column}</TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align='center'>{totals.total.toFixed(2)} </TableCell>
                                <TableCell align='center'>{totals.withoutMarkup.toFixed(2)}</TableCell>
                                <TableCell align='center'>{totals.markup.toFixed(2)}</TableCell>
                                <TableCell align='center'>{totals.units}</TableCell>
                                <TableCell align='center'>{totals.nights}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}

const enhance = compose(
    connect(
        state => ({
            resortPackages: state.packages.resortPackages,
            lodgingDropdownInfo: state.packages.lodgingDropdownInfo,
            roomDropdownInfo: state.packages.roomDropdownInfo,
            selectedRooms: state.quotes.selectedRooms
        }),
        dispatch => ({
            updateSelectedRoom(payload) {
                dispatch(quotesActions.updateSelectedRoom(payload));
            },
            replaceSelectedRoom(payload) {
                dispatch(quotesActions.replaceSelectedRoom(payload));
            },
            addSelectedRoom(payload) {
                dispatch(quotesActions.addSelectedRoom(payload));
            },
            saveSelectedRoom(payload) {
                dispatch(quotesActions.saveSelectedRoom(payload));
            },
            deleteSelectedRoom(payload) {
                dispatch(quotesActions.deleteSelectedRoom(payload));
            },
            updateItem(payload) {
                dispatch(quotesActions.updateGolfVacationItem(payload));
            }
        })
    )
);

export default enhance(memo(Lodging));