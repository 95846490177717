import React, { Fragment, useState } from 'react';
import { debounce } from '../../config';
import AsyncSelect from 'react-select/async';
import { getOrderProducts } from '../../services/orders';

const ProductAutocomplete = (props) => {

    const [options, setOptions] = useState([]);
    const { handleValueChange, placeholder } = props;

    const getOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }

        getOrderProducts({ query: inputValue })
            .then(response => {
                if (response.status === 200) {
                    setOptions(response.data.results);
                    callback(response.data.results);
                }
            });
    }

    const debouncedProductSearch = debounce(getOptions);

    return (
        <Fragment>
            <AsyncSelect
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 2147483647 }),
                    menu: provided => ({ ...provided, zIndex: 2147483647 })
                }}
                value={props.value}
                menuPortalTarget={document.body}
                cacheOptions
                defaultOptions={options}
                loadOptions={debouncedProductSearch}
                onChange={handleValueChange}
                placeholder={placeholder ? placeholder : 'Select...'}
            />
        </Fragment>
    );
}

export default ProductAutocomplete;