import { useEffect, useState, useCallback } from "react";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { packagesActions } from "../../../redux";
import { List } from "@mui/material";
import CoursePackageCard from "./CoursePackageCard";
import { getCoursesByPackageId } from "../../../services/package";
import { toastr } from "react-redux-toastr";
import update from 'immutability-helper';


const CoursePackageList = (props) => {

    const [courseList, setCourseList] = useState([])

    useEffect(() => {
        if (props.packageId !== 0) {
            fetchCoursesByPackageId(props.packageId);
        } {
            setCourseList([]);
        }
    }, [props.packageId]);

    const fetchCoursesByPackageId = (id) => {
        getCoursesByPackageId({ id })
            .then(response => {
                if (response.status === 200) {
                    setCourseList(response.data)
                }
                else {
                    toastr.error('', 'Failed to fetch courses');
                }
            });
    }

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        let updatedList = update(courseList, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, courseList[dragIndex]]
            ],
        });
        setCourseList(updatedList);
        let reorderedCourses = updatedList.map((item, index) => ({ id: item.id, position: index + 1, title: item.title }));
        props.updateCourseOrder(reorderedCourses);
    }, [courseList]);

    const updateListItemMostImportantFlag = (id, flagValue) => {
        let flagText = flagValue === 1 ? 'on' : 'off';
        setCourseList(courseList.map(course => course.id === id ? {...course, most_important: flagText} : course));
    }

    return (
        <List>
            {courseList.map((card, index) => {
                return <CoursePackageCard
                    key={card.id}
                    index={index}
                    id={card.id}
                    card={card}
                    moveCard={moveCard}
                    updateListItemMostImportantFlag={updateListItemMostImportantFlag}
                />
            })}
        </List>
    );
}

const enhance = compose(
    connect(
        state => ({}),
        dispatch => ({
            updateCourseOrder(payload) {
                dispatch(packagesActions.updateCoursesOrder(payload));
            },
        })
    )
);

export default enhance(CoursePackageList);