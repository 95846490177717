import React, { useState, useEffect, memo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { calculateTotals, useClasses } from '../../../../../config';
import styles from './styles';
import { Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody } from '@mui/material';
import { quotesActions } from '../../../../../redux';

const columnNames = ['Lodging', '', 'Golf', '', 'Additional Amounts', '', 'Total Cost', '', 'Lodging Markup', '', 'Golf Markup', '', 'Additional Amounts Markup', '', 'Package Price'];

const Totals = (props) => {
    
    const classes = useClasses(styles);
    const [info, setInfo] = useState(0);
    const { id, itemOccupancy, totals, additionalAmounts, selectedCourses, selectedRooms, updateItem } = props;

    useEffect(() => {
        if (id) {
            //TODO: Move logic in Redux
            let lodgingList;
            if (itemOccupancy === 'golf_only') {
                lodgingList = [];
            } else {
                lodgingList = selectedRooms[id]
            }
            let totals = calculateTotals({
                selectedCourses: selectedCourses[id],
                selectedRooms: lodgingList,
                additionalAmounts: additionalAmounts
            });
            updateItem({target: 'totals', value: totals, id: id});
            setInfo(totals);
        }
    }, [ selectedCourses, selectedRooms]);

    useEffect(() => {
        if (totals) {
            setInfo(totals);
        }
    }, [totals]);

    return (
        <TableContainer component={Paper} className={classes.totalsContainer}>
            <Table style={{ width: '100%' }} aria-label='simple table'>
                <TableHead>
                    <TableRow>
                        {columnNames.map((column, index) => <TableCell style={{ fontWeight: 900 }} key={index}
                            align='center'>{column}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align='center'>{info.lodging} </TableCell>
                        <TableCell align='center' style={{ fontWeight: 900, lineHeight: '2em', fontSize: '1.5em' }}> + </TableCell>
                        <TableCell align='center'>{info.courses} </TableCell>
                        <TableCell align='center' style={{ fontWeight: 900, lineHeight: '2em', fontSize: '1.5em' }}> + </TableCell>
                        <TableCell align='center'>{parseFloat(info.additional_amount).toFixed(2)} </TableCell>
                        <TableCell align='center' style={{ fontWeight: 900, lineHeight: '2em', fontSize: '1.5em' }}> = </TableCell>
                        <TableCell align='center'>{(parseFloat(info.courses) + parseFloat(info.lodging) + parseFloat(info.additional_amount)).toFixed(2)} </TableCell>
                        <TableCell align='center' style={{ fontWeight: 900, lineHeight: '2em', fontSize: '1.5em' }}> + </TableCell>
                        <TableCell align='center'>{info.lodging_markup} </TableCell>
                        <TableCell align='center' style={{ fontWeight: 900, lineHeight: '2em', fontSize: '1.5em' }}> + </TableCell>
                        <TableCell align='center'>{info.courses_markup} </TableCell>
                        <TableCell align='center' style={{ fontWeight: 900, lineHeight: '2em', fontSize: '1.5em' }}> + </TableCell>
                        <TableCell align='center'>{parseFloat(info.additional_amount_markup).toFixed(2)} </TableCell>
                        <TableCell align='center' style={{ fontWeight: 900, lineHeight: '2em', fontSize: '1.5em' }}> = </TableCell>
                        <TableCell align='center' style={{ color: 'blue' }}>{info.total} </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const enhance = compose(
    connect(
        state => ({
            selectedRooms: state.quotes.selectedRooms,
            selectedCourses: state.quotes.selectedCourses
        }),
        dispatch => ({
            updateItem(payload) {
                dispatch(quotesActions.updateGolfVacationItem(payload));
            }
        })
    )
);

export default enhance(Totals);