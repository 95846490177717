import React from 'react';
import './App.css';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { theme } from './config';
import { history } from './redux';
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AppRoutes from './routes/AppRoutes';

function App() {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Router history={history}>
				<DndProvider backend={HTML5Backend}>
					<AppRoutes />
				</DndProvider>
			</Router>
		</ThemeProvider>
	);
}

export default App;
