import { Fragment, useRef, useState, useMemo } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from '../../../../config/ItemTypes.js';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IconButton, Tooltip, Switch } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import GolfVacationItemInfo from './GolfVacationItemInfo.js';
import DownloadIcon from '@mui/icons-material/Download';
import { setGolfVacationItemConfirmFlag, setGolfVacationItemPublishFlag } from '../../../../services/quotes.js';
import { toastr } from 'react-redux-toastr';
import { quotesActions } from '../../../../redux/index.js';
import PreviewGolfVacationItemTooltip from '../../../../components/popup/PreviewGolfVacationItemTooltip.js';
import { ConfirmDialog } from '../../../../components/dialog';
import { getRounds, Constants } from '../../../../config';
import { checkAndAppendTermsAndConditions } from '../../../../normalizers/GolfVacationItemNormalizer.js';

const GolfVacationItemCard = (props) => {

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const ref = useRef(null);

    const { id, quoteId, quoteUnderReview, item, index, updateItem, duplicateItem, deleteItem, moveCard, classes, isActive, onClickAction } = props;

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const nightsAndRoundsText = useMemo(() => {
        let shouldShowNights = true;
        let shouldShowTotal = false;
        if ((item.occupancy && item.occupancy === 'golf_only') || (props.selectedRooms && props.selectedRooms[id] && props.selectedRooms[id].length === 0)) {
            shouldShowNights = false;
        }

        if (item.totals && item.totals.total_rounded !== undefined) {
            shouldShowTotal = true;
        }

        const nights = item.nights;
        const rounds = getRounds(props.selectedCourses[id]);
        let nightsAndRoundsText = ' ( ';
        if (shouldShowTotal) {
            nightsAndRoundsText += item.totals.total_rounded + ' -';
        }
        if (shouldShowNights) {
            nightsAndRoundsText += ` ${nights} ${nights == 1 ? 'night' : 'nights'} - ${rounds} ${rounds == 1 ? 'round' : 'rounds'} )`;
        } else {
            nightsAndRoundsText += ` ${rounds} ${rounds == 1 ? 'round' : 'rounds'} )`;
        }
        return nightsAndRoundsText;
    }, [item, id, props.selectedRooms.length, props.selectedCourses.length]);

    const handleConfirmFlagChange = (e) => {
        const flagValue = e.target.checked ? 1 : 0;
        setGolfVacationItemConfirmFlag({ id: item.id, flag: flagValue })
            .then(response => {
                if (response.status === 204) {
                    let updatedValue = flagValue == 1 ? 2 : 1;
                    if (updatedValue == 2) {
                        let description = checkAndAppendTermsAndConditions(item.description || '', updatedValue);
                        updateItem({ target: 'description', value: description, id: item.id });
                    }
                    updateItem({ target: 'status', value: updatedValue, id: item.id });
                    toastr.success('', 'Successfully toggled confirmed flag');
                }
                else {
                    toastr.error('', 'Confirmed toggle failed');
                }
            });
    }

    const handlePublishFlagChange = (e) => {
        const flagValue = e.target.checked ? 1 : 0;
        setGolfVacationItemPublishFlag({ id: item.id, flag: flagValue })
            .then(response => {
                if (response.status === 204) {
                    updateItem({ target: 'published', value: flagValue, id: item.id });
                    toastr.success('', 'Successfully toggled published flag');
                }
                else {
                    toastr.error('', 'Confirmed toggle failed');
                }
            });
    }

    const handleSendConfirmationPdf = () => {
        if (quoteUnderReview) {
            const userConfirmed = window.confirm(Constants.UNDER_REVIEW_CONFIRM_ACTION_MESSAGE);
            if (!userConfirmed) return;
        }

        props.sendConfirmationPdf({ id: quoteId });
    }

    return (
        <Fragment>
            <div ref={ref} data-handler-id={handlerId}>
                <div className={classes.golfVacationItem}>
                    <span>
                        <Tooltip title='Delete'>
                            <IconButton className={classes.golfVacationDeleteIcon} onClick={() => { setShowDeleteDialog(true) }}>
                                <DeleteIcon sx={{ color: 'black' }} />
                            </IconButton>
                        </Tooltip>
                        <span onClick={onClickAction}>
                            {`${id} ${item.title} ${nightsAndRoundsText}`}
                        </span>
                        <Fragment>
                            <Switch checked={item.status == 2} onChange={(e) => handleConfirmFlagChange(e)} /> Confirmed
                            <Switch checked={item.published != 0} onChange={(e) => handlePublishFlagChange(e)} /> Published
                        </Fragment>
                    </span>
                    <span className={classes.dropCardSection} onClick={onClickAction}>
                        {/* Element used in order to click only o a section of the card in order to open */}
                    </span>
                    <PreviewGolfVacationItemTooltip id={id} maxGolfers={item.number_golfers.value} />
                    {
                        item.status == 2 &&
                        <Fragment>
                            <Tooltip title='Download Confirmation PDF'>
                                <IconButton onClick={() => props.downloadConfirmationPdf({ id: quoteId })} className={classes.golfVacationIcon}>
                                    <CloudDownloadIcon sx={{ color: 'black' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Send Confirmation PDF'>
                                <IconButton onClick={handleSendConfirmationPdf} className={classes.golfVacationIcon}>
                                    <AttachEmailIcon sx={{ color: 'black' }} />
                                </IconButton>
                            </Tooltip>
                        </Fragment>
                    }
                    {
                        item.hasCourseWithVoucher &&
                        <Fragment>
                            <Tooltip title={`${item.voucher_email_sent ? ' Resend' : 'Send'} voucher ${item.paidInFull ? '' : '(Not Fully paid)'}`}>
                                <div className={classes.iconSpan}>
                                    <IconButton
                                        onClick={() => props.sendVoucher({ id: quoteId })}
                                        className={classes.golfVacationIcon}
                                        disabled={item.paidInFull == false}
                                    >
                                        <CardMembershipIcon sx={{ color: item.paidInFull ? 'black' : 'grey' }} />
                                    </IconButton>
                                </div>
                            </Tooltip>

                            <Tooltip title={'Download Voucher PDF'}>
                                <div className={classes.iconSpan}>
                                    <IconButton
                                        onClick={() => props.downloadVoucherPdf({ id: quoteId })}
                                        className={classes.golfVacationIcon}
                                        disabled={item.paidInFull == false}
                                    >
                                        <DownloadIcon sx={{ color: item.paidInFull ? 'black' : 'grey' }} />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Fragment>
                    }
                    <Tooltip title='Duplicate'>
                        <IconButton onClick={() => duplicateItem(id, index, item)} className={classes.golfVacationIcon}>
                            <FileCopyIcon sx={{ color: 'black' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            {isActive && <GolfVacationItemInfo
                classes={classes}
                item={item}
            />}
            <ConfirmDialog
                open={showDeleteDialog}
                text='Are you sure you want to delete this vacation item?'
                okText='Yes'
                okAction={() => deleteItem({ id })}
                cancelText='No'
                cancelAction={() => { setShowDeleteDialog(false) }}
            />
        </Fragment>
    )
}

const enhance = compose(
    connect(
        state => ({
            quoteUnderReview: state.quotes.quoteInfo.quoteUnderReview,
            selectedRooms: state.quotes.selectedRooms,
            selectedCourses: state.quotes.selectedCourses
        }),
        dispatch => ({
            downloadConfirmationPdf(payload) {
                dispatch(quotesActions.downloadConfirmationPdf(payload));
            },
            sendConfirmationPdf(payload) {
                dispatch(quotesActions.sendConfirmationPdf(payload));
            },
            downloadVoucherPdf(payload) {
                dispatch(quotesActions.downloadVoucherPdf(payload));
            },
            sendVoucher(payload) {
                dispatch(quotesActions.sendVoucher(payload));
            },
            updateItem(payload) {
                dispatch(quotesActions.updateGolfVacationItem(payload));
            },
            deleteItem(payload) {
                dispatch(quotesActions.deleteGolfVacationItem(payload));
            }
        })
    )
);

export default enhance(GolfVacationItemCard);