import React, { Fragment, useState, useEffect } from 'react';
import { debounce } from '../../config';
import AsyncSelect from 'react-select/async';
import { getUsersByEmail } from '../../services/orders';

const UserEmailAutocomplete = (props) => {

    const [options, setOptions] = useState([]);
    const { handleUserInputChange, users } = props;

    useEffect(() => {
        setOptions(users);
    }, [users]);

    const getOptions = (inputValue, callback) => {
        if (!inputValue) {
            return callback([]);
        }

        getUsersByEmail({ query: inputValue })
            .then(response => {
                if (response.status === 200) {
                    setOptions(response.data.results);
                    callback(response.data.results);
                }
            });
    }

    const debouncedUsersByEmailSearch = debounce(getOptions);

    return (
        <Fragment>
            <AsyncSelect
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 2147483647, minWidth: 500 }),
                    menu: provided => ({ ...provided, zIndex: 2147483647 })
                }}
                cacheOptions
                defaultOptions={options}
                loadOptions={debouncedUsersByEmailSearch}
                onChange={handleUserInputChange}
            />
        </Fragment>
    );
}

export default UserEmailAutocomplete;