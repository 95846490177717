import React, { useEffect, useMemo } from 'react';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { useClasses } from '../../config';
import styles from './styles';

const TeeTimeReminder = (props) => {

    const { teeTimeReminders } = props;
    const { primary, secondary, pendingPrimary, pendingSecondary } = teeTimeReminders;
    const navigate = useNavigate();
    const classes = useClasses(styles);

    useEffect(() => {
        props.getTeeTimeReminders();
    }, []);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'vacation_title',
                header: 'Title',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'course_title',
                header: 'Course title',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'last_name',
                header: 'Last name',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'course_date',
                header: 'Course date',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'course_time',
                header: 'Course time',
                size: 50,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'nr_days',
                header: 'TBD days',
                size: 50,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
        ],
        [],
    );


    return (
        <div className='mainSection'>
            <div className={classes.table}>
                <MaterialReactTable
                    columns={columns}
                    data={primary}
                    enableRowActions
                    positionActionsColumn='last'
                    muiTablePaperProps={{ elevation: 16 }}
                    renderTopToolbar={() =>
                        <div className={classes.tableTitle}>
                            <Typography variant='h6'>Confirmed</Typography>
                        </div>
                    }
                    renderRowActions={({ row, table }) => (
                        <Tooltip title="Edit">
                            <IconButton
                                onClick={() => { navigate(`/golf-vacation/${row.original.vacation_id}`) }}
                            >
                                <EditIcon sx={{color: 'black'}}/>
                            </IconButton>
                        </Tooltip>
                    )}
                />
            </div>
            <div className={classes.table}>
                <MaterialReactTable
                    columns={columns}
                    data={secondary}
                    enableRowActions
                    positionActionsColumn='last'
                    muiTablePaperProps={{
                        elevation: 16,
                    }}
                    renderTopToolbar={() =>
                        <div className={classes.tableTitle}>
                            <Typography variant='h6'>Confirmed Secondary</Typography>
                        </div>
                    }
                    renderRowActions={({ row, table }) => (
                        <Tooltip title="Edit">
                            <IconButton
                                onClick={() => { navigate(`/golf-vacation/${row.original.vacation_id}`) }}
                            >
                                <EditIcon sx={{color: 'black'}}/>
                            </IconButton>
                        </Tooltip>
                    )}
                />
            </div>
            <div className={classes.table}>
                <MaterialReactTable
                    columns={columns}
                    data={pendingPrimary}
                    enableRowActions
                    positionActionsColumn='last'
                    muiTablePaperProps={{ elevation: 16 }}
                    renderTopToolbar={() =>
                        <div className={classes.tableTitle}>
                            <Typography variant='h6'>Pending</Typography>
                        </div>
                    }
                    renderRowActions={({ row, table }) => (
                        <Tooltip title="Edit">
                            <IconButton
                                onClick={() => { navigate(`/golf-vacation/${row.original.vacation_id}`) }}
                            >
                                <EditIcon sx={{color: 'black'}}/>
                            </IconButton>
                        </Tooltip>
                    )}
                />
            </div>
            <div className={classes.table}>
                <MaterialReactTable
                    columns={columns}
                    data={pendingSecondary}
                    enableRowActions
                    positionActionsColumn='last'
                    muiTablePaperProps={{ elevation: 16 }}
                    renderTopToolbar={() =>
                        <div className={classes.tableTitle}>
                            <Typography variant='h6'>Pending Secondary</Typography>
                        </div>
                    }
                    renderRowActions={({ row, table }) => (
                        <Tooltip title="Edit">
                            <IconButton
                                onClick={() => { navigate(`/golf-vacation/${row.original.vacation_id}`) }}
                            >
                                <EditIcon sx={{color: 'black'}}/>
                            </IconButton>
                        </Tooltip>
                    )}
                />
            </div>
        </div>
    );
}

export default TeeTimeReminder;