import React, { Component } from 'react';
import { Button, Card, TextField, Alert, Typography, IconButton, InputAdornment, Snackbar, AlertTitle } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link } from 'react-router-dom';

class Register extends Component {
    state = {
        username: { value: '', error: '' },
        email: { value: '', error: '' },
        name: { value: '', error: '' },
        password: { value: '', error: '' },
        confirmedPassword: { value: '', error: '' },
        showPassword: false,
        showConfirmedPassword: false,
    }

    handleRegister = () => {
        this.validateRequiredFields();
        this.validateFieldsValues();
        const { username, email, name, password } = this.state;
        this.props.register({username, email, name, password, role: 'TEST_ROLE' });
    }

    validateRequiredFields = () => {
        const { username, email, name, password, confirmedPassword } = this.state;
        const emptyFieldMessage = 'Field must not be empty!';
        this.setState((prevState, props) => ({
            username: { ...username, error: prevState.username.value ? '' : emptyFieldMessage },
            email: { ...email, error: prevState.email.value ? '' : emptyFieldMessage },
            name: { ...name, error: prevState.name.value ? '' : emptyFieldMessage },
            password: { ...password, error: prevState.password.value ? '' : emptyFieldMessage },
            confirmedPassword: { ...confirmedPassword, error: prevState.confirmedPassword.value ? '' : emptyFieldMessage },
        }));
    }

    validateFieldsValues = () => {
        const { email, password, confirmedPassword } = this.state;
        let emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
        if (email.value) {
            if (!emailRegex.test(email.value)) {
                this.setState({ email: { value: '', error: 'Please enter a valid email address.' } })
            }
        }
        if (password.value && confirmedPassword.value) {
            if (password.value !== confirmedPassword.value) {
                this.setState({
                    password: { value: '', error: 'Please enter the same password in both fields.' },
                    confirmedPassword: { value: '', error: 'Please enter the same password in both fields.' }
                })
            }
        }
    }

    handleInputChange = input => event => {
        this.setState({
            [input]: { ...this.state[input], value: event.target.value }
        });
    };

    handleShowPassword = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword
        }));
    }

    handleShowConfirmedPassword = () => {
        this.setState((prevState) => ({
            showConfirmedPassword: !prevState.showConfirmedPassword
        }));
    }

    handleSnackbarClose = () => {
        this.props.setRegistrationFailed(false);
    }

    render() {
        const { username, email, name, password, confirmedPassword, showPassword, showConfirmedPassword } = this.state;
        const { classes } = this.props;

        return (
            <div className={classes.container}>
                <Card elevation={4} className={classes.form}>
                    <Typography className={classes.title} color='primary'>Registration</Typography>
                    <div className={classes.fields}>
                        <TextField
                            label='Username'
                            value={username.value}
                            onChange={this.handleInputChange('username')}
                            error={username.error ? true : false}
                            helperText={username.error}
                            required
                        />
                        <TextField
                            label='Email'
                            value={email.value}
                            onChange={this.handleInputChange('email')}
                            error={email.error ? true : false}
                            helperText={email.error}
                            required
                        />
                        <TextField
                            label='Name'
                            value={name.value}
                            onChange={this.handleInputChange('name')}
                            error={name.error ? true : false}
                            helperText={name.error}
                            required
                        />
                        <TextField
                            label='Password'
                            value={password.value}
                            onChange={this.handleInputChange('password')}
                            type={showPassword ? 'text' : 'password'}
                            error={password.error ? true : false}
                            helperText={password.error}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={this.handleShowPassword}>
                                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <TextField
                            label='Confirm Password'
                            value={confirmedPassword.value}
                            onChange={this.handleInputChange('confirmedPassword')}
                            type={showConfirmedPassword ? 'text' : 'password'}
                            error={confirmedPassword.error ? true : false}
                            helperText={confirmedPassword.error}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={this.handleShowConfirmedPassword}>
                                            {showConfirmedPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Button 
                            fullWidth 
                            className={classes.regButton}
                            variant='contained' 
                            color='secondary' 
                            onClick={this.handleRegister}
                        >
                            Sign up
                        </Button>
                    </div>
                    <Typography className={classes.membershipMessage} color='grey'>
                        Already a member? <Link to='/login'>Login now</Link>
                    </Typography>
                </Card>
                {/* <Snackbar open={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
                    <Alert severity="success">
                        <AlertTitle><strong>Successful registration!</strong></AlertTitle>
                        The account has been created. You will be contected via email when the required permissions will be granted.
                        See you later!
                    </Alert>
                </Snackbar> */}
                <Snackbar open={this.props.registrationFailed} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={this.handleSnackbarClose}>
                    <Alert severity="error">Something went wrong. Please contact tech support.</Alert>
                </Snackbar>
            </div>
        );
    }
}

export default Register;