export const types = {
    GET_RESORT_PACKAGES: 'GET_RESORT_PACKAGES',
    GET_RESORT_PACKAGES_SUCCESS: 'GET_RESORT_PACKAGES_SUCCESS',
    GET_RESORT_PACKAGES_FAILED: 'GET_RESORT_PACKAGES_FAILED',

    GET_COURSES_PACKAGES: 'GET_COURSES_PACKAGES',
    GET_COURSES_PACKAGES_SUCCESS: 'GET_COURSES_PACKAGES_SUCCESS',
    GET_COURSES_PACKAGES_FAILED: 'GET_COURSES_PACKAGES_FAILED',

    GET_LODGINGS_PACKAGES: 'GET_LODGINGS_PACKAGES',
    GET_LODGINGS_PACKAGES_SUCCESS: 'GET_LODGINGS_PACKAGES_SUCCESS',
    GET_LODGINGS_PACKAGES_FAILED: 'GET_LODGINGS_PACKAGES_FAILED',

    GET_COURSES_BY_PACKAGE_ID: 'GET_COURSES_BY_PACKAGE_ID',
    GET_COURSES_BY_PACKAGE_ID_SUCCESS: 'GET_COURSES_BY_PACKAGE_ID_SUCCESS',
    GET_COURSES_BY_PACKAGE_ID_FAILED: 'GET_COURSES_BY_PACKAGE_ID_FAILED',

    UPDATE_COURSES_ORDER: 'UPDATE_COURSES_ORDER',
    UPDATE_LODGINGS_ORDER: 'UPDATE_LODGINGS_ORDER',

    UPDATE_COURSE_MOST_IMPORTANT_FLAG: 'UPDATE_COURSE_MOST_IMPORTANT_FLAG',
    UPDATE_LODGING_MOST_IMPORTANT_FLAG: 'UPDATE_LODGING_MOST_IMPORTANT_FLAG',
}