import { types } from "./types";

export const actions = {
    login: payload => ({
        type: types.LOGIN,
        payload
    }), 
    loginSuccess: payload => ({
        type: types.LOGIN_SUCCESS,
        payload
    }),
    loginFailed: payload => ({
        type: types.LOGIN_FAILED,
        payload
    }),
    setLoginFailed: payload => ({
        type: types.SET_LOGIN_FAILED,
        payload
    }),

    register: payload => ({
        type: types.REGISTER,
        payload
    }),
    registrationSuccess: payload => ({
        type: types.REGISTRATION_SUCCESS,
        payload
    }),
    registrationFailed: payload => ({
        type: types.REGISTRATION_FAILED,
        payload
    }),
    setRegistrationFailed: payload => ({
        type: types.SET_REGISTRATION_FAILED,
        payload
    })
}