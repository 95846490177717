import { types } from "./types";

export const actions = {

    saveOrder: payload => ({
        type: types.SAVE_ORDER,
        payload
    }),
    saveOrderSuccess: payload => ({
        type: types.SAVE_ORDER_SUCCESS,
        payload
    }),
    saveOrderFailed: payload => ({
        type: types.SAVE_ORDER_FAILED,
        payload
    }),

    getOrderById: payload => ({
        type: types.GET_ORDER_BY_ID,
        payload
    }),
    getOrderByIdSuccess: payload => ({
        type: types.GET_ORDER_BY_ID_SUCCESS,
        payload
    }),
    getOrderByIdFailed: payload => ({
        type: types.GET_ORDER_BY_ID_FAILED,
        payload
    }),

    deleteOrder: payload => ({
        type: types.DELETE_ORDER,
        payload
    }),
    deleteOrderSuccess: payload => ({
        type: types.DELETE_ORDER_SUCCESS,
        payload
    }),
    deleteOrderFailed: payload => ({
        type: types.DELETE_ORDER_FAILED,
        payload
    }),

    getOrders: payload => ({
        type: types.GET_ORDERS,
        payload
    }),
    getOrdersSuccess: payload => ({
        type: types.GET_ORDERS_SUCCESS,
        payload
    }),
    getOrdersFailed: payload => ({
        type: types.GET_ORDERS_FAILED,
        payload
    }),

    resetOrderProductsList: payload => ({
        type: types.RESET_ORDER_PRODUCTS_LIST,
        payload
    }),
    getOrderProductsRequest: payload => ({
        type: types.GET_ORDER_PRODUCTS_REQUEST,
        payload
    }),
    getOrderProducts: payload => ({
        type: types.GET_ORDER_PRODUCTS,
        payload
    }),
    getOrderProductsSuccess: payload => ({
        type: types.GET_ORDER_PRODUCTS_SUCCESS,
        payload
    }),
    getOrderProductsFailed: payload => ({
        type: types.GET_ORDER_PRODUCTS_FAILED,
        payload
    }),

    getUsersByEmailRequest: payload => ({
        type: types.GET_USERS_BY_EMAIL_REQUEST,
        payload
    }),
    getUsersByEmail: payload => ({
        type: types.GET_USERS_BY_EMAIL,
        payload
    }),
    getUsersByEmailSuccess: payload => ({
        type: types.GET_USERS_BY_EMAIL_SUCCESS,
        payload
    }),
    getUsersByEmailFailed: payload => ({
        type: types.GET_USERS_BY_EMAIL_FAILED,
        payload
    }),

    getUsersByProduct: payload => ({
        type: types.GET_USERS_BY_PRODUCT,
        payload
    }),
    getUsersByProductSuccess: payload => ({
        type: types.GET_USERS_BY_PRODUCT_SUCCESS,
        payload
    }),
    getUsersByProductFailed: payload => ({
        type: types.GET_USERS_BY_PRODUCT_FAILED,
        payload
    }),

    splitOrder: payload => ({
        type: types.SPLIT_ORDER,
        payload
    }),
    splitOrderSuccess: payload => ({
        type: types.SPLIT_ORDER_SUCCESS,
        payload
    }),
    splitOrderFailed: payload => ({
        type: types.SPLIT_ORDER_FAILED,
        payload
    }),

    moveOrder: payload => ({
        type: types.MOVE_ORDER,
        payload
    }),
    moveOrderSuccess: payload => ({
        type: types.MOVE_ORDER_SUCCESS,
        payload
    }),
    moveOrderFailed: payload => ({
        type: types.MOVE_ORDER_FAILED,
        payload
    }),
}