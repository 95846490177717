export const emailTemplates = {
   proposal: {
      title: `Golf Vacation Proposal`,
      template: ` 
            <p>Hello {{user_first_name}} -</p>

            <p>Thank you for sending through the request for your golf vacation. I certainly appreciate it!</p>

            <p><span>I have put together <b>X</b> golf vacation packages for you that you can view with this link: <a href="{{link_url}}" target="_blank">{{link_url}}</a></span></p>

            <p><span>Please note that you can access additional info on your web page about these golf vacations under&nbsp;<b>'Golf Vacation Notes'</b>.</span></p>

            <p>I hope this information helps. If you have any questions, interested in other options, or would like to book one of these golf packages, please don't hesitate to reach out.</p>

            <p>Thanks, {{user_first_name}}!</p>

            <p>Sean</p>

            <p>--</p>

            <div dir="ltr" class="gmail_signature" data-smartmail="gmail_signature">
               <div><b><span color="#0000ff" size="4" style="color: #0000ff; font-size: large;">Sean Petersen</span></b></div>
               <div dir="ltr">
                  <div><b><i>Founder &amp; Golf Vacation Specialist</i></b></div>
                  <div><b><i>&nbsp;</i></b></div>
                  <div><b><span color="#0000ff" style="color: #0000ff;">M: 1.877.634.0790</span></b></div>
                  <div><b><span color="#0000ff" style="color: #0000ff;">&nbsp;</span></b></div>
                  <div><b><span color="#0000ff" style="color: #0000ff;"><a href="https://golftripjunkie.com/" target="_blank"><span size="4" style="font-size: large;">GOLFTRIPJUNKIE.COM</span></a><span>&nbsp;|&nbsp;</span></span></b><b><span color="#0000ff" size="2" style="color: #0000ff; font-size: small;"><a href="mailto:sean@golftripjunkie.com" target="_blank">sean@golftripjunkie.com</a></span></b></div>
                  <div>&nbsp;</div>
                  <div><img src="https://golftripjunkie.com/frontend/default/images/gtj_logo_gmail.png" style="height: 93px; width:200px;"/></div>
                  <div><img src="https://golftripjunkie.com/frontend/default/images/iagto_logo_gmail.jpg" style="height:110px; width:200px; border:0;" /></div>
               </div>
            </div>
        `
   },
   confirmation: {
      title: `Confirmation from Golf Trip Junkie`,
      template: `
            <p>Hi {{user_first_name}} -</p>

            <p>Your golf vacation has been confirmed! You can access your information by clicking on this link: <a href="{{link_url}}" target="_blank">{{link_url}}</a></p>\r\n

            <p>Please review the info in the 'Golf Vacation Notes' section.</p>

            <p><b>The following is information for how your group can pay their deposits, as well as future payments:</b></p>

            <ol>
               <li>Click on your link: <a href="{{link_url}}" target="_blank">{{link_url}}</a></li>
               <li>Make Payment</li>
               <li>Deposit Payments</li>
               <ul>
                  <li>Select Pay My Deposit Only or Pay Deposit for Entire Group</li>
               </ul>
               <li>Future Payments (multiple payments can be made leading up to the trip)</li>
               <ul>
                  <li>Pay Balance for Entire Group</li>
                  <li><span style="background-color: mark; color: marktext;"><b>Pay Custom Amount (can also be used for deposit payments)</b></span>&nbsp;</li>
               </ul>
               <li>Enter Payment Details</li>
               <li>Complete Payment</li>
            </ol>

            <b>Also, prior to your trip, whenever you have it figured out (and if you have not already provided done so), please send over the following information:</b>
            <ul>
               <li>Rooming list (first and last name)</li>
               <li>Caddie requirements (see Important Info &amp; Fees for additional info)</li>
            </ul>

            <span>
               <b>Included in this email is a link to a complimentary Golf Games book (PDF format). You can click here to download your copy: </b>
               <a href="https://golftripjunkie.com/uploads/Golf%20Games%20-%20Golf%20Trip%20Junkie%27s%20Favorite%20Wagering%20Games%20(v97a).pdf" download>
                  <span>Games Golf PDF</span>
               </a>
            </span>

            <p>If you have any questions do not hesitate to reach out. I'm always available to help out in any way that I can.</p>

            <p>Thanks, {{user_first_name}}!</p>

            <p>Sean</p>

            <p>--</p> 

            <div dir="ltr" class="gmail_signature" data-smartmail="gmail_signature">
               <div><b><span color="#0000ff" size="4" style="color: #0000ff; font-size: large;">Sean Petersen</span></b></div>
               <div dir="ltr">
                  <div><b><i>Founder &amp; Golf Vacation Specialist</i></b></div>
                  <div><b><i>&nbsp;</i></b></div>
                  <div><b><span color="#0000ff" style="color: #0000ff;">M: 1.877.634.0790</span></b></div>
                  <div><b><span color="#0000ff" style="color: #0000ff;">&nbsp;</span></b></div>
                  <div><b><span color="#0000ff" style="color: #0000ff;"><a href="https://golftripjunkie.com/" target="_blank"><span size="4" style="font-size: large;">GOLFTRIPJUNKIE.COM</span></a><span>&nbsp;|&nbsp;</span></span></b><b><span color="#0000ff" size="2" style="color: #0000ff; font-size: small;"><a href="mailto:sean@golftripjunkie.com" target="_blank">sean@golftripjunkie.com</a></span></b></div>
                  <div>&nbsp;</div>
                  <div><img src="https://golftripjunkie.com/frontend/default/images/gtj_logo_gmail.png" style="height: 93px; width:200px;"/></div>
                  <div><img src="https://golftripjunkie.com/frontend/default/images/iagto_logo_gmail.jpg" style="height:110px; width:200px; border:0;" /></div>
               </div>
            </div>
        `
   },
};