const styles = theme => ({
    container: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        minHeight: 610,
        width: 550,
        padding: '40px 55px',
        position: 'relative',
    },
    fields: {
        margin: '20px 0 20px 0',
        padding: '0 25px 0 25px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        height: 410
    },
    title: {
        position: 'relative',
        fontSize: '30px !important',
        fontWeight: 600,
        '&before': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: 0,
            height: 4,
            width: 42,
            borderRadius: 12,
            backgroundColor: '#0c9c04'
        }
    },
    regButton: {
        marginTop: '10px !important'
    },
    membershipMessage: {
        alignSelf:'center'
    }
});

export default styles;