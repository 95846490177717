import React, { Fragment } from 'react';
import { Navigate } from "react-router-dom";
import styles from './styles';
import Menu from '../components/menu';
import { useClasses } from '../config';

const PrivateRoute = (props) => {
    const { children } = props;
    const classes = useClasses(styles);
    const isLoggedIn = localStorage.getItem('accessToken');
    return (
        isLoggedIn
            ? <Fragment>
                <div className={classes.root}>
                    <Menu />
                    <main className={classes.content}>
                        {children}
                    </main>
                </div>
            </Fragment>
            : <Navigate to="/login" replace />
    );
}

export default PrivateRoute;