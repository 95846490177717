import { compose } from 'redux';
import { connect } from 'react-redux';
import { quotesActions } from '../../redux';
import DueQuotes from './DueQuotes';

const enhance = compose(
    connect(
        state => ({
            dueQuotesList: state.quotes.dueQuotesList,
        }),
        dispatch => ({
            getDueQuotes(payload) {
                dispatch(quotesActions.getDueQuotes(payload));
            },
            sendPaymentReminderEmail(payload) {
                dispatch(quotesActions.sendPaymentReminderEmail(payload));
            },
            deleteQuote(payload) {
                dispatch(quotesActions.deleteQuote(payload));
            },
            hideQuote(payload) {
                dispatch(quotesActions.hideQuote(payload));
            }
        })
    )
);

export default enhance(DueQuotes);