const styles = theme => ({
    actionsColumn: {
        minWidth: 160,
        display: 'flex',
        justifyContent: 'center'
    },
    dueQuotesTable: {
        '& table': {
            '& th': {
                lineHeight: '1rem',
                fontWeight: 600,
                paddingBottom: '0.4rem',
                '& div': {
                    whiteSpace: 'unset'
                }
            }
        }
    },
    tableTitle: {
        padding: '10px 10px 20px 20px',
        fontWeight: '500'
    }
});

export default styles;