import React, { useState, useEffect, useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { formatDate, useClasses } from '../../config';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { useNavigate } from 'react-router-dom';
import { Tooltip, IconButton } from '@mui/material';
import { ConfirmDialog } from '../../components/dialog';
import styles from './styles';

const Leads = (props) => {

    const initialPageSize = parseInt(localStorage.getItem('leadsPageSize')) || 10;
    const initialSearchText = localStorage.getItem('leadsSearch') || '';

    const [searchText, setSearchText] = useState(initialSearchText);
    const [showSpamDialog, setShowSpamDialog] = useState(false);
    const [selectedLeadIdForSpam, setSelectedLeadIdForSpam] = useState(0);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedLeadIdForDeletion, setSelectedLeadIdForDeletion] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: initialPageSize,
    });
    const navigate = useNavigate();
    const classes = useClasses(styles);
    const { leadsList, totalPages } = props;

    useEffect(() => {
        const { pageIndex, pageSize } = pagination;
        const params = {
            page: pageIndex + 1, perPage: pageSize, search: searchText ? searchText : ''
        }
        props.getLeads(params);
    },
        [
            pagination.pageIndex,
            pagination.pageSize,
            searchText
        ]);

    useEffect(() => {
        localStorage.setItem('leadsPageSize', pagination.pageSize);
    }, [pagination.pageSize]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'source',
                header: 'Source',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'device',
                header: 'Device',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'name',
                header: 'Name',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'lead_date',
                header: 'Lead Date',
                size: 70,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
                Cell: ({ cell }) => {
                    return <div>{formatDate(cell.getValue())}</div>;
                },
            },
            {
                accessorKey: 'email',
                header: 'Email',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'phone',
                header: 'Phone',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'msg',
                header: 'Message',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
        ],
        [],
    );

    const showConfirmationDialogForSpam = (id) => {
        setSelectedLeadIdForSpam(id);
        setShowSpamDialog(true);
    }

    const showConfirmationDialogForDeletion = (id) => {
        setSelectedLeadIdForDeletion(id);
        setShowDeleteDialog(true);
    }

    const markLeadAsSpam = () => {
        props.markLeadAsSpam({ id: selectedLeadIdForSpam });
        setShowSpamDialog(false);
    }

    const deleteLead = () => {
        props.deleteLead({ id: selectedLeadIdForDeletion });
        setShowDeleteDialog(false);
    }

    const handleSetSearchChange = (value) => {
        let searchValue = value == undefined ? '' : value;
        localStorage.setItem('leadsSearch', searchValue);
        setSearchText(searchValue);
    }

    return (
        <div className={`leadsSection mainSection ${classes.leadsTable}`}>
            <MaterialReactTable
                columns={columns}
                data={leadsList}
                rowCount={totalPages}
                initialState={{
                    showGlobalFilter: true
                }}
                state={{
                    globalFilter: searchText,
                    pagination
                }}
                manualPagination
                manualFiltering
                positionGlobalFilter='left'
                muiSearchTextFieldProps={{
                    sx: { minWidth: '450px' },
                    variant: 'outlined',
                }}
                onPaginationChange={setPagination}
                muiPaginationProps={{
                    rowsPerPageOptions: [10, 50, 100, 200]
                }}
                onGlobalFilterChange={handleSetSearchChange}
                enableFilterMatchHighlighting={false}
                autoResetPageIndex={false}
                muiTablePaperProps={{ elevation: 12 }}
                defaultColumn={{ size: 50 }}
                enableSorting={false}
                enableColumnActions={false}
                enableRowActions
                positionActionsColumn='last'
                renderRowActions={({ row, table }) => (
                    <div className={classes.actionButtons}>
                        <Tooltip title="Edit">
                            <IconButton
                                onClick={() => {
                                    navigate('/golf-vacation-from-lead',
                                        {
                                            state: {
                                                lead_id: row.original.id,
                                                name: row.original.name,
                                                email: row.original.email,
                                                info: row.original.extra_data
                                            }
                                        }
                                    )
                                }}
                            >
                                <EditIcon sx={{ color: 'black' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete lead">
                            <IconButton
                                onClick={() => { showConfirmationDialogForDeletion(row.original.id) }}
                            >
                                <DeleteIcon sx={{ color: 'black' }} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Mark quote as spam">
                            <IconButton
                                onClick={() => { showConfirmationDialogForSpam(row.original.id) }}
                            >
                                <DoDisturbIcon sx={{ color: 'black' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                )}
                enableDensityToggle={false}
                enableColumnFilters={false}
                enableHiding={false}
                enableFullScreenToggle={false}
            />
            <ConfirmDialog
                open={showSpamDialog}
                text='Are you sure you want to mark this lead as spam?'
                okText='Yes'
                okAction={markLeadAsSpam}
                cancelText='No'
                cancelAction={() => { setShowSpamDialog(false) }}
            />
            <ConfirmDialog
                open={showDeleteDialog}
                text='Are you sure you want to delete this lead?'
                okText='Yes'
                okAction={deleteLead}
                cancelText='No'
                cancelAction={() => { setShowDeleteDialog(false) }}
            />
        </div>);
}

export default Leads;