import { call, put, takeLatest, all } from 'redux-saga/effects';
import { userService } from '../services';
import { push } from "redux-first-history";
import { toastr } from 'react-redux-toastr';
import {
    userActions,
    userTypes,
} from '../redux';


const login = function* (action) {
    try {
        const response = yield call(userService.login, action.payload);
        localStorage.setItem('accessToken', response.data.token);
        localStorage.setItem('role', response.data.role);
        localStorage.setItem('userId', response.data.userId);

        yield put(userActions.loginSuccess());
        yield put(push('/quotes'));
    } catch (e) {
        console.log(e);
        yield put(userActions.loginFailed());
        toastr.error('', 'Invalid username or password');
    }
};

const register = function* (action) {
    try {
        const response = yield call(userService.register, action.payload);

        //TODO: update response when connected to the real endpoint
        yield put(userActions.registrationSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(userActions.registrationFailed());
    }

}

export default function* userSaga() {
    yield all([
        takeLatest(userTypes.LOGIN, login),
        takeLatest(userTypes.REGISTER, register)
    ]);
}