import { compose } from 'redux';
import { connect } from 'react-redux';
import { userActions } from '../../redux';
import Register from './Register';

const enhance = compose(
    connect(
        state => ({
            successfulRegistration: state.user.successfulRegistration,
            registrationFailed: state.user.registrationFailed
        }),
        dispatch => ({
            register(payload) {
                dispatch(userActions.register(payload));
            },
            setRegistrationFailed(payload) {
                dispatch(userActions.setRegistrationFailed(payload));
            }
        })
    )
);

export default enhance(Register);