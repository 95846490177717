const styles = theme => ({
    sectionTitle: {
        backgroundColor: theme.palette.secondary.main,
        padding: '1rem',
        width: '90%',
        borderTopRightRadius: 30,
        borderBottomRightRadius: 30,
        color: theme.palette.white,
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1
    },
    buttonsContainer: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        width: '60%',
        height: '60%',
        padding: 30,
        border: '2px dashed #ccc',
        marginTop: 30,
    },
    selectFileContainer: {
        display: 'flex'
    },
    selectedFileName: {
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    coursesSection: {
        height: 350
    },
    lodgingSection: {
        height: 350,
        marginTop: 20
    }
});

export default styles;