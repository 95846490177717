const styles = theme => ({
    sectionTitle: {
        backgroundColor: theme.palette.secondary.main,
        padding: '1rem',
        width: '90%',
        borderTopRightRadius: 30,
        borderBottomRightRadius: 30,
        color: theme.palette.white,
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1
    },
    buttonsSection: {
        marginBottom: 20
    },
    content: {
        marginTop: 20
    },
    card: {
        marginBottom: 5
    },
    package: {
        backgroundColor: theme.palette.grey.table
    },
    selectedPackage: {
        backgroundColor: '#c7c7c7'
    }
});

export default styles;