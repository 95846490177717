import React, { useRef } from 'react';
import BundledEditor from './BundledEditor';

const RichTextEditor = (props) => {
    const editorRef = useRef(null);

    return (
        <BundledEditor
            onInit={(evt, editor) => editorRef.current = editor}
            init={{
                height: props.height ? props.height : 500,
                menubar: false,
                plugins: [
                    'advlist', 'anchor', 'autolink', 'image', 'link', 'media', 'lists',
                    'searchreplace', 'table', 'wordcount'
                ],
                toolbar: 'undo redo | blocks | bold italic underline strikethrough | forecolor backcolor blockquote ' +
                    '| link image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ' +
                    '| removeformat ',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                image_advtab: true,
            }}
            initialValue={props.initialContent}
            value={props.content}
            onEditorChange={(newValue, editor) => props.handleContentChange(newValue)}
        />
    );
}

export default RichTextEditor;
