const styles = theme => ({
    additionalAmountsSection: {
        backgroundColor: '#dde0dc',
        width: '40%',
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
    },
    additionalAmountsTitle: {
        paddingLeft: 10
    },
    additionalAmountRow: {
        marginTop: '5px !important'
    },
    additionalAmountsContainer: {
        marginTop: 5,
        marginBottom: 30
    },
    totalBox: {
        color: 'rgba(0, 0, 0, 0.62)',
        fontWeight: 600
    },
    card: {
        border: '2px dotted #ccc',
        borderRadius: 6,
        width: '100%',
        minHeight: 400,
        padding: 20
    },
    dateRangeContainer: {
        height: 50
    },
    unitsAndCostContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20
    },
    unitsAndCostField: {
        marginRight: '8px !important'
    },
    totalsContainer: {
        marginBottom: 20
    },
    dateRangeInput: {
        position: 'relative',
        top: '-23px',
        left: 0,
        minHeight: 40,
        width: '100%',
        borderColor: 'rgba(0,0,0,0.23)',
        '& div': {
            borderRadius: 4,
            borderColor: 'rgba(0,0,0,0.23)',
        }
    },
    coursesContainer: {
        marginBottom: 20
    },
    courseTableDate: {
        marginTop: 46,
        '& div': {
            width: 'fit-content'
        },
        minHeight: 32,
    },
    courseTableCalendar: {
        width: '350px !important',
        fontSize: '1rem',
        '& div': {
            width: '100%'
        }
    },
    dateRangeLabel: {
        width: 'max-content',
        backgroundColor: '#ffffff',
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: '1rem',
        lineHeight: '1.4375em',
        position: 'relative',
        display: 'block',
        transformOrigin: 'top left',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        left: 0,
        top: 0,
        transform: 'translate(14px, -7px) scale(0.75)',
        zIndex: 1,
    },
    lodgingContainer: {
        marginBottom: 20
    },
    lodgingLeftContainer: {
        paddingRight: 20
    },
    contentBox: {
        '& div': {
            padding: '18.5px 14px '
        }
    },
    resort: {
        backgroundColor: `${theme.palette.grey.table} !important`,
        paddingTop: '5px !important',
        paddingBottom: '5px !important'
    },
    selectedResort: {
        backgroundColor: `${theme.palette.primary.light} !important`,
    },
    resortText: {
        textTransform: 'capitalize',
    },
    bedIcon: {
        paddingRight: 3,
        margin: '0 5px 0 15px',
        color: 'black',
    },
    room: {
        paddingTop: '3px !important',
    },
    totalsTable: {
        marginTop: 20
    },
    titleColumns: {
        width: 240,
        padding: '2px 2px !important'
    },
    closeLabel: {
        textAlign: 'center',
        fontSize: '0.9rem !important'
    },
    textfieldColumns: {
        width: 70,
        padding: '2px 2px !important'
    },
    coursesTableColumns: {
        padding: '2px 2px !important'
    },
    tableHead: {
        backgroundColor: theme.palette.secondary.light,
        borderBottom: `2px solid ${theme.palette.secondary.main}`,
        '& th': {
            fontWeight: 600
        }
    },
    tableTextfield: {
        width: 70,
        '& input': {
            padding:3,
            paddingRight:5,
            paddingLeft: 5,
            textAlign: 'center'
        }
    },
    tableSelect: {
        '& div': {
            paddingTop: 2,
            paddingBottom: 2
        }
    },
    overflowContainer: {
        height: 400,
        overflow: 'auto'
    },
    coursesTableContainer: {
        overflow: 'visible !important'
    },
    coursesTable: {
        '& td, th': {
            padding: '0px 4px',
        }
    },
    coursesTime: {
        width: 70,
        display: 'flex'
    },
    courseTableHead: {
        backgroundColor: `${theme.palette.primary.light} !important`,
        borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    coursesTitleColumn: {
        minWidth: '15rem',
        width: 230,
    },
    timeSelect: {
        marginTop: 0,
        height: '2.5em',
        borderRadius: 4,
        background: 'transparent',
        width: 45,
        border: '1px solid rgba(0,0,0,0.23)',

    },
    fieldAndIconContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 15
    },
    courseAndIconContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    }
});

export default styles;