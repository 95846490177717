import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary: {
			// dark blue
			main: '#2b3469',
			light: '#d5e2ed'
		},
		secondary: {
			// green
			main: '#0c9c04',
			dark: '#0d890d',
			light: '#dfeddf',

		},
		grey: {
			main: '#7E7E7E',
			icon: 'rgba(0, 0, 0, 0.54)',
			table: '#f5f5f5'
		},
		white: '#FFF',
		red: '#d32f2f'
 	},
	typography: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
    },
  	overrides: {
		MuiOutlinedInput: {
			input: {
				padding: '20px 12px',
			},
			// root: {
			// 	borderRadius: 4,
			// },
		},
		MuiTableBody: {
			root: {
				backgroundColor: '#f5f5f5',
				'& tr:nth-of-type(odd)': {
					backgroundColor: '#f5f5f5',
				},
			}
		}
	}
});

export default theme;