import { compose } from 'redux';
import { connect } from 'react-redux';
import { packagesActions, quotesActions } from '../../../redux';
import Quote from './Quote';

const enhance = compose(
    connect(
        state => ({
            quoteInfo: state.quotes.quoteInfo,
            password: state.quotes.password,
            items: state.quotes.golfVacationItems,
            resortPackages: state.packages.resortPackages,
            selectedRooms: state.quotes.selectedRooms,
            selectedCourses: state.quotes.selectedCourses,
        }),
        dispatch => ({
            getQuoteById(payload) {
                dispatch(quotesActions.getQuoteById(payload));
            },
            saveQuote(payload) {
                dispatch(quotesActions.saveQuote(payload));
            },
            updateQuote(payload) {
                dispatch(quotesActions.updateQuote(payload));
            },
            getResortPackages() {
                dispatch(packagesActions.getResortPackages());
            },
            setGolfVacationItems(payload) {
                dispatch(quotesActions.setGolfVacationItems(payload));
            },
            setSelectedRooms(payload) {
                dispatch(quotesActions.setSelectedRooms(payload));
            },
            setSelectedCourses(payload) {
                dispatch(quotesActions.setSelectedCourses(payload));
            },
        })
    )
);

export default enhance(Quote);
